import React from "react"
import cx from "classnames"

// Components
import StyledCard from "../../../../../ui-kit/StyledCard/StyledCard"

// Styles
import * as styles from "./CertifiedSellersPlaceholder.module.sass"

const CertifiedSellersPlaceholder = ({ comingSoon }) => {
  return (
    <StyledCard>
      <div className={styles.block}>
        {comingSoon && <h3 className={styles.coming}>{comingSoon}</h3>}
        <div className={styles.top}>
          <div className={cx(styles.avatar, styles.animation)} />
          <div className={styles.nameStar}>
            <div className={cx(styles.line, styles.animation)} />
            <div className={cx(styles.line, styles.animation)} />
            <div className={cx(styles.line, styles.animation)} />
          </div>
        </div>
        <div className={styles.middle}>
          <div className={cx(styles.line, styles.animation)} />
          <div className={cx(styles.line, styles.animation)} />
        </div>
        <div className={cx(styles.bottom, styles.animation)} />
      </div>
    </StyledCard>
  )
}

export default CertifiedSellersPlaceholder
