import React, { useRef, useLayoutEffect } from "react"
import * as d3 from "d3"
import cx from "classnames"
import applyDropShadow from "./Filters"
import * as s from "./total-hash-chart.module.sass"
import { Scrollbars } from "react-custom-scrollbars"

const renderThumb = ({ style, ...props }) => {
  const thumbStyle = {
    background: "#163878",
    boxShadow: "-4px -4px 7px #FFFFFF, 5px 5px 7px #DAE0E9",
    borderRadius: "100px",
    width: "5px",
  }
  return <div style={{ ...style, ...thumbStyle }} {...props} />
}

const TotalHashChart = ({ data, w, h, id }) => {
  const d3Container = useRef(null)
  const yAxisRef = useRef(null)
  const scrollBar = useRef(null)

  const margin = {
    top: 30,
    right: 0,
    bottom: 30,
    left: 0,
  }
  const itemsPerScreen = () => {
    if (typeof window !== "undefined" && window.innerWidth > 1336) return 4
    if (typeof window !== "undefined" && window.innerWidth > 1024) return 3
    if (
      typeof window !== "undefined" &&
      window.innerWidth < 1024 &&
      window.innerWidth > 600
    )
      return 6
    if (typeof window !== "undefined" && window.innerWidth < 450) return 3
    if (typeof window !== "undefined" && window.innerWidth < 600) return 5
  }

  const widthCoef = data.length / itemsPerScreen()
  const width = widthCoef > 1 ? w * widthCoef : w * 0.7

  const height = h
  const easeLinearAnimation = d3.easeLinear
  const render = () => {
    scrollBar.current && scrollBar.current.scrollToRight()
    d3.selectAll(`.${s.line}`).remove()
    d3.selectAll(`.${s.bar}`).remove()
    d3.selectAll(`.${s.xAxis}`).remove()
    d3.selectAll(`.${s.yAxis}`).remove()
    const svg = d3.select(d3Container.current)
    const yAxisContainer = d3.select(yAxisRef.current)

    if (data.length && d3Container.current) {
      const x = d3.scaleBand().rangeRound([width, 0])
      const y = d3.scaleLinear().range([height, margin.bottom])
      x.domain(data.map(d => d.date))
      const yMax = d3.max(data, d => d.value)
      y.domain([0, yMax + yMax * 0.2]).nice()

      d3.axisBottom(x).tickPadding(1).ticks(5).scale()

      const yAxisScale = d3.axisLeft(y).scale()
      const yAxis = yAxisScale.ticks(5)

      const getXAxisOffset = label => {
        if (label.length >= 10) {
          return 35
        }
        if (label.length >= 8) {
          return 25
        }
        return 5
      }
      const createXAxisNode = () =>
        svg
          .selectAll("xAxis")
          .data(data)
          .enter()
          .append("span")
          .attr("font-size", "12")
          .attr("fill", "#8F9BB2")
          .attr("class", s.xAxis)
          .attr(
            "style",
            d =>
              `opacity:0;transform: translate(${
                d.date.length > 2
                  ? x(d.date) + x.bandwidth() / 2 - getXAxisOffset(d.date) + 8
                  : x(d.date) + x.bandwidth() / 2 + 8
              }px,-15px)`
          )
          .text(d => d.date)
          .style("text-anchor", "middle")
          .transition(easeLinearAnimation)
          .style("opacity", 1)
          .delay((d, i) => d && i * 50)

      const pad = h / yAxis.length
      const createYAxisNode = () => {
        yAxis.reverse().map((t, i) => {
          const top = pad * i
          yAxisContainer
            .append("span")
            .attr("font-size", "12")
            .attr("fill", "#8F9BB2")
            .attr("class", s.yAxis)
            .attr(
              "style",
              () => `transform: translate(0,${top - 10}px);opacity:0;`
            )
            .text(t)
            .style("text-anchor", "middle")
            .transition(easeLinearAnimation)
            .style("opacity", 1)
            .delay(i * 75)
          return svg
            .append("div")
            .attr("class", s.line)
            .attr(
              "style",
              `transform: translate(${margin.left}px,${top.toFixed(
                0
              )}px);opacity:0;width:${width - margin.right}px`
            )
            .transition(easeLinearAnimation)
            .style("opacity", 1)
            .delay(i * 25)
        })
      }
      createYAxisNode()
      createXAxisNode()

      svg
        .selectAll("bar")
        .data(data)
        .enter()
        .append("div")
        .attr("class", s.bar)
        .attr("style", d => {
          const bottom = () => {
            if (yAxis.length === 5) return 30
            if (yAxis.length === 6) return 35

            if (yAxis.length === 7) return 30

            return 30
          }
          const left = x(d.date) + x.bandwidth() / 2 - 1.5
          return `left:${left}px;bottom:${bottom()}px;min-height:0`
        })
        .transition(easeLinearAnimation)
        .style("height", d => `${height - y(d.value)}px`)
        .delay((d, i) => d && i * 100)

      svg.append("use").attr("xlink:href", `.${id}-horizontal-line`)

      const defs = svg.append("defs")
      applyDropShadow(defs)
    }
  }

  useLayoutEffect(() => {
    render()
    return () => {
      d3.selectAll(`.${id}`).exit().remove()
    }
  }, [data, width, d3Container.current])

  return (
    <div className={s.flex}>
      {data.length ? (
        <div
          ref={yAxisRef}
          className={s.yAxisContainer}
          style={{ height: `${h}px` }}
        />
      ) : null}

      <div className={cx(s.chartWrapper, { [s.flex]: !data.length })}>
        {data.length ? (
          <Scrollbars
            autoHeightMax="276px"
            autoHeight
            renderThumbHorizontal={style => renderThumb(style)}
            renderTrackVertical={props => (
              <div {...props} style={{ display: "none" }} />
            )}
            ref={scrollBar}
          >
            <div
              className="d3-component"
              id={id}
              ref={d3Container}
              style={{ width, height: h }}
            />
          </Scrollbars>
        ) : (
          <span className={s.text}>No data available</span>
        )}
      </div>
      <canvas id="sales-canvas" width={0} height={0}></canvas>
    </div>
  )
}

export default TotalHashChart
