import React from "react"

// Styles
import * as styles from "./TrustPilotRating.module.sass"

const TrustPilotStarFilled = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
  >
    <rect width="25" height="25" fill="#50B27F" />
    <path
      d="M11.5 3L13.4084 8.87336L19.584 8.87336L14.5878 12.5033L16.4962 18.3766L11.5 14.7467L6.50383 18.3766L8.41219 12.5033L3.41602 8.87336L9.59163 8.87336L11.5 3Z"
      fill="white"
    />
  </svg>
)

const TrustPilotStarGray = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
  >
    <rect width="25" height="25" fill="#DCDCE5" />
    <path
      d="M12.5 3L14.6329 9.56434H21.535L15.9511 13.6213L18.084 20.1857L12.5 16.1287L6.91604 20.1857L9.04892 13.6213L3.46496 9.56434H10.3671L12.5 3Z"
      fill="white"
    />
  </svg>
)

const TrustPilotRating = ({ rating }) => {
  const realRating = rating > 5 ? 5 : rating < 0 ? 0 : rating

  return (
    <div className={styles.trustPilotRatingWrapper}>
      {new Array(realRating).fill(0).map((_el, index) => (
        <TrustPilotStarFilled key={index} />
      ))}

      {new Array(5 - realRating).fill(0).map((_el, index) => (
        <TrustPilotStarGray key={index} />
      ))}
    </div>
  )
}

export default TrustPilotRating
