import React from "react"
import dayjs from "dayjs"
import relativeTime from "dayjs/plugin/relativeTime"
import TrustPilotRating from "../../../../ui-kit/TrustPilotRating/TrustPilotRating"

import VerifiedIcon from "../../../../icons/light/reviews/verified.svg"
import VerifiedIconDark from "../../../../icons/dark/reviews/verified.svg"

import { useCurrentTheme } from "../../../../utils/themeChanger"

// Styles
import * as styles from "./TrustPilotReview.module.sass"
dayjs.extend(relativeTime)

const TrustPilotReview = ({
  rating,
  verified,
  time,
  reviewerName,
  title,
  text,
}) => {
  const colorTheme = useCurrentTheme()
  const isLightTheme = colorTheme.data?.theme === "light"

  return (
    <div className={styles.reviewContainer}>
      <div className={styles.reviewRow}>
        <TrustPilotRating rating={rating} />
        {verified && (
          <div className={styles.verifiedReview}>
            <img
              alt="Verified"
              src={isLightTheme ? VerifiedIcon : VerifiedIconDark}
            />
            <span>Verified</span>
          </div>
        )}
      </div>
      <div className={styles.reviewData}>
        <span>{`${reviewerName}, `}</span>
        <span className={styles.reviewTime}>{dayjs(time).fromNow()}</span>
      </div>

      <p className={styles.reviewTitle}>{title}</p>
      <p className={styles.reviewText}>{text}</p>
    </div>
  )
}

export default TrustPilotReview
