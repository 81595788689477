import React from "react"
import dayjs from "dayjs"
// import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

// Components
import StyledCard from "../../../../ui-kit/StyledCard/StyledCard"
import Button from "../../../../ui-kit/Button/Button"
import PlaceHolder from "./VerifiedDataCentersPlaceholder/VerifiedDataCentersPlaceholder"

// Icons
import YouTubeIcon from "../../../../icons/light/youtube.svg"
import YouTubeDarkIcon from "../../../../icons/dark/youtube.svg"
import PlaceholderReseller from "../../../../icons/light/placeholders/placeholder-reseller.svg"
import ReviewCount from "../../../../icons/light/reviewsCount.svg"
import ReviewCountDark from "../../../../icons/dark/reviewsCount.svg"
import { useCurrentTheme } from "../../../../utils/themeChanger"

// Styles
import * as styles from "./VerifiedDatatCenters.module.sass"
import StarsReview from "../Rating/Ratting"

const VerifiedBlock = ({ data, headers, lang = "en" }) => {
  const colorTheme = useCurrentTheme()
  const isLightTheme = colorTheme.data?.theme === "light"

  let content = []

  // if (data.loading) {
  //   content.push(
  //     <>
  //       <div className={styles.card}>
  //         <PlaceHolder />
  //       </div>
  //       <div className={styles.card}>
  //         <PlaceHolder />
  //       </div>
  //     </>
  //   )
  // }

  data.map(center => {
    content.push(
      <div className={styles.card} key={center.id}>
        <StyledCard>
          <div className={styles.block}>
            <div className={styles.top}>
              <div className={styles.avatar}>
                {center.image ? (
                  <div className={styles.avatarImage}>
                    <Img
                      fluid={center.image.localFile.childImageSharp.fluid}
                      alt={`${center.first_name} ${center.last_name}`}
                    />
                  </div>
                ) : (
                  <div className={styles.avatarPlaceholder}>
                    <img alt="Avatar" src={PlaceholderReseller} />
                  </div>
                )}
              </div>
              <div className={styles.nameStar}>
                <h3>
                  {center.first_name} {center.last_name}
                </h3>
                <div className={styles.star}>
                  <StarsReview rating={center.rating} />
                  <div className={styles.reviews}>
                    <img
                      alt="Reviews"
                      src={isLightTheme ? ReviewCount : ReviewCountDark}
                    />
                    {center.reviews_count}
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.middle}>
              <div>
                <div className={styles.line}>
                  <h4>{headers.onboarded}:</h4>{" "}
                  <p>{dayjs(center.created_at).format("MMMM DD, YYYY")}</p>
                </div>
                <div className={styles.line}>
                  <h4>{headers.totalHashrate}:</h4>{" "}
                  <p>{center.total_hashrate} Th/s</p>
                </div>
                <div className={styles.line}>
                  <h4>{headers.electricity}:</h4>{" "}
                  <p>
                    ${center.m12_price_monthly_electricity_cost} - $
                    {center.m2_price_monthly_electricity_cost}/KW
                  </p>
                </div>
              </div>
              <div>
                <div className={styles.line}>
                  <h4>{headers.availableHardware}:</h4>
                  <p>{center.available_hardware}</p>
                </div>
                <div className={styles.line}>
                  <h4>{headers.itemsOnZionodes}:</h4>
                  <p>{center.items_on_zionodes}</p>
                </div>
              </div>
            </div>
            <div className={styles.bottom}>
              <div className={styles.button}>
                <Button link="https://marketplace.zionodes.com/" external>
                  {headers.contact}
                </Button>
              </div>
              {center.youtube_link && (
                <Button
                  theme="ternary iconed"
                  link={center.youtube_link}
                  className={styles.youtubeLink}
                  external
                >
                  <img
                    alt="YouTube"
                    src={isLightTheme ? YouTubeIcon : YouTubeDarkIcon}
                  />
                </Button>
              )}
            </div>
          </div>
        </StyledCard>
      </div>
    )

    if (data.length < 2) {
      for (let i = 0; i < 2 - data.length; i++) {
        content.push(
          <div className={styles.card} key={`verified-data-centers-${i}`}>
            <PlaceHolder comingSoon={"Coming soon..."} />
          </div>
        )
      }
    }
  })

  return <div className={styles.container}>{content}</div>
}

export default VerifiedBlock
