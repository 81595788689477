// extracted by mini-css-extract-plugin
export var active = "testimonials-module--active--JjXnQ";
export var backgroundIcon = "testimonials-module--backgroundIcon--77wZz";
export var button = "testimonials-module--button--frv-c";
export var buttons = "testimonials-module--buttons--lB5iZ";
export var card = "testimonials-module--card--mEQfW";
export var cardInner = "testimonials-module--cardInner--qfM0f";
export var cardOpacity = "testimonials-module--cardOpacity--O5Py7";
export var cardStyle = "testimonials-module--cardStyle--nsXK3";
export var comment = "testimonials-module--comment--6C-0T";
export var content = "testimonials-module--content--FlZqi";
export var date = "testimonials-module--date--a-2sk";
export var heading = "testimonials-module--heading--fzi5O";
export var icon = "testimonials-module--icon--5tQ7V";
export var iconImage = "testimonials-module--iconImage--TyNCP";
export var image = "testimonials-module--image--9cC9V";
export var imageInner = "testimonials-module--imageInner--NFz8o";
export var imageMask = "testimonials-module--imageMask--2aw7A";
export var inner = "testimonials-module--inner--CWoMx";
export var opacity = "testimonials-module--opacity--a5P5V";
export var reverse = "testimonials-module--reverse--8DLAg";
export var section = "testimonials-module--section--f0pNz";
export var transform = "testimonials-module--transform--aXSTI";