import React from "react"
import cx from "classnames"
import Select from "react-select"

// Styles
import * as s from "./select.module.sass"

const customSingleValue = ({ data }) => (
  <span title={data.label}>{data.label}</span>
)

const CustomOption = ({
  innerProps,
  isDisabled,
  isSelected,
  isFocused,
  data,
}) =>
  !isDisabled ? (
    <div
      {...innerProps}
      title={data.label}
      className={cx(s.option, { [s.optionSelect]: isSelected })}
    >
      <span>{data.label}</span>
    </div>
  ) : null

const CustomSelect = ({
  label,
  className,
  inputTheme,
  error,
  instanceId,
  minified,
  ...props
}) => (
  <div className={cx(s.container, { [s.errorLabel]: error }, className)}>
    {label && (
      <div
        className={cx(s.label, {
          [s.labelPadding]: inputTheme?.includes("labelPadding"),
        })}
      >
        {label}
      </div>
    )}
    <div
      className={cx(s.select, {
        [s.h46]: inputTheme?.includes("h46"),
        [s.selectMini]: minified,
      })}
    >
      <Select
        components={{
          SingleValue: customSingleValue,
          Option: CustomOption,
        }}
        classNamePrefix="customSelect"
        {...props}
        options={props.options}
        value={props.value}
        className={cx({
          [s.filterSelectError]: error,
          [s.filterSelectSuccess]: props.success,
          [s.filterSelectSelected]:
            Boolean(props.value) ||
            error ||
            props.success ||
            inputTheme?.includes("border"),
          [s.filterSelect]: !(
            Boolean(props.value) ||
            error ||
            props.success ||
            inputTheme?.includes("border")
          ),
        })}
        instanceId={instanceId || "filterSelect"}
      />
    </div>
    {!inputTheme?.includes("noError") && (
      <span className={s.error}>{error}</span>
    )}
  </div>
)

export default CustomSelect
