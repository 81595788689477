import React, { useEffect, useRef, useState } from "react"
import cx from "classnames"

// Components
import StyledCard from "../../../../ui-kit/StyledCard/StyledCard"

// Icons
import AttentionIcon from "../../../../icons/light/attention.svg"

// Styles
import * as s from "./calculator.module.sass"
import Input from "../../../../ui-kit/Input/Input"
import CustomSelect from "../../../../ui-kit/Select/Select"
import PlusMinusInput from "../../../../ui-kit/PlusMinusInput/PlusMinusInput"
import Button from "../../../../ui-kit/Button/Button"
import { ADMIN_ROUTE, API_ROUTE } from "../../../../constants"
import { toast } from "react-toastify"

let initialValues = {
  elCost: 0.01,
  price: 500,
  currency: {
    label: "USD - $",
    value: {
      number: 1,
      currency: "USD",
    },
  },
  items: 1,
  model: {
    label: "Loading...",
    value: "",
  },
}

const initialResult = {
  initial: true,
  loading: false,
  error: false,
  fetched: false,
}

const Calculator = ({ data, lang }) => {
  const [inputsValues, setInputsValues] = useState(initialValues)
  const [btcUsd, setBtcUsd] = useState(0)
  const [inputsErrors, setInputsError] = useState({
    elCost: false,
    price: false,
  })
  const resultRef = useRef(null)
  const calculatorRef = useRef(null)

  const [models, setModels] = useState({
    loading: true,
    error: false,
    fetchData: [],
  })
  // const [currencies, setCurrencies] = useState({
  //   loading: true,
  //   error: false,
  //   fetchData: [],
  // })
  const [result, setResult] = useState({ ...initialResult, fetchData: {} })
  const [scrolling, setScrolling] = useState(null)

  useEffect(() => {
    fetch(`${API_ROUTE}/core/asics/models/`)
      .then(res => res.json())
      .then(json => {
        const models = json.results.map(model => ({
          label: model.model,
          value: {
            hashrate: model.declared_hashrate,
            power: model.nominal_consumption,
          },
        }))
        setModels({ ...models, fetchData: models, loading: false })
        setInputsValues({ ...inputsValues, model: models[0] })
        initialValues = { ...inputsValues, model: models[0] }
      })
      .catch(err => {
        console.log(err)
      })

    // fetch(`${EXCHANGE_ROUTE}/exchangerates_data/latest?base=USD`, {
    //   headers: { apikey: "QMzkz4eXSSPP1dJX5dCTDMnlLj8GweKp" },
    // })
    //   .then(res => res.json())
    //   .then(json => {
    //     setCurrencies({
    //       ...currencies,
    //       loading: false,
    //       fetchData: [
    //         {
    //           label: "USD - $",
    //           value: {
    //             number: 1,
    //             currency: "USD",
    //           },
    //         },
    //         {
    //           label: "HKD - HK$",
    //           value: {
    //             number: json.rates.HKD,
    //             currency: "HKD",
    //           },
    //         },
    //         {
    //           label: "GBP - £",
    //           value: {
    //             number: json.rates.GBP,
    //             currency: "GBP",
    //           },
    //         },
    //         {
    //           label: "BRL - R$",
    //           value: {
    //             number: json.rates.BRL,
    //             currency: "BRL",
    //           },
    //         },
    //         {
    //           label: "RUB - ₽",
    //           value: {
    //             number: json.rates.RUB,
    //             currency: "RUB",
    //           },
    //         },
    //         {
    //           label: "JPY - ¥",
    //           value: {
    //             number: json.rates.JPY,
    //             currency: "JPY",
    //           },
    //         },
    //         {
    //           label: "CHF - CHF",
    //           value: {
    //             number: json.rates.CHF,
    //             currency: "CHF",
    //           },
    //         },
    //         {
    //           label: "EUR - €",
    //           value: {
    //             number: json.rates.EUR,
    //             currency: "EUR",
    //           },
    //         },
    //         {
    //           label: "CNY - ¥",
    //           value: {
    //             number: json.rates.CNY,
    //             currency: "CNY",
    //           },
    //         },
    //         {
    //           label: "KRW - ₩",
    //           value: {
    //             number: json.rates.KRW,
    //             currency: "KRW",
    //           },
    //         },
    //         {
    //           label: "THB - ฿",
    //           value: {
    //             number: json.rates.THB,
    //             currency: "THB",
    //           },
    //         },
    //       ],
    //     })
    //   })
    //   .catch(e => {
    //     console.log(e)
    //   })
  }, [])

  const calculate = () => {
    if (!inputsErrors.elCost && !inputsErrors.price) {
      setResult({ ...result, initial: false, loading: true })
      const link = `${ADMIN_ROUTE}/calculator/coins?hr=${(
        inputsValues.model.value.hashrate * 1000
      ).toFixed(1)}&p=${inputsValues.model.value.power.toFixed(
        1
      )}&fee=0.0&cost=${
        inputsValues.elCost / inputsValues.currency.value.number
      }&hcost=0.0`

      fetch(link)
        .then(res => res.json())
        .then(json => {
          setResult({
            ...result,
            loading: false,
            fetchData: {
              revenue:
                parseFloat(
                  json.revenue.charAt(0) === "-"
                    ? -1 * json.revenue.slice(2)
                    : json.revenue.slice(1)
                ) * inputsValues.currency.value.number,
              profit:
                parseFloat(
                  json.profit.charAt(0) === "-"
                    ? -1 * json.profit.slice(2)
                    : json.profit.slice(1)
                ) * inputsValues.currency.value.number,
            },
            fetched: true,
          })
          setScrolling("bottom")
        })
        .catch(err => {
          setResult({ ...result, initial: true, loading: false, error: true })
          toast.error(data.error)
        })
    }
  }

  useEffect(() => {
    if (scrolling === "bottom" && resultRef.current) {
      setTimeout(() => {
        resultRef.current.scrollIntoView({
          behavior: "smooth",
          block: "center",
        })
      }, 300)
    }
    if (scrolling === "top" && calculatorRef.current) {
      setTimeout(() => {
        calculatorRef.current.scrollIntoView({
          behavior: "smooth",
          block: "center",
        })
      }, 300)
    }
  }, [scrolling])

  useEffect(() => {
    fetch("https://blockchain.info/ticker")
      .then(res => res.json())
      .then(json => {
        setBtcUsd(json.USD.last)
      })
  }, [])

  return (
    <StyledCard theme="reverse notHoverable">
      <div className={s.content} ref={calculatorRef}>
        <div className={s.contentCol}>
          <div className={s.input}>
            {models.loading ? (
              ""
            ) : (
              <CustomSelect
                label={data.devices}
                options={models.fetchData}
                value={inputsValues.model}
                onChange={model => {
                  setResult({ ...result, fetched: false })
                  setInputsValues({ ...inputsValues, model: model })
                }}
              />
            )}
          </div>
          <div className={s.input}>
            <div className={s.itemsWrapper}>
              <div className={s.itemsLabel}>{data.numberOfDevices}</div>
              <div className={s.itemsInput}>
                <PlusMinusInput
                  minValue={1}
                  value={inputsValues.items}
                  onChange={val => {
                    setResult({ ...result, fetched: false })
                    setInputsValues({ ...inputsValues, items: val })
                  }}
                  item={data.item}
                  items={data.items}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={s.contentCol}>
          {/* <div className={s.input}>
            {currencies.loading ? (
              ""
            ) : (
              <CustomSelect
                label={data.currency}
                options={currencies.fetchData}
                value={inputsValues.currency}
                onChange={currency => {
                  setResult({ ...result, fetched: false })
                  setInputsValues({ ...inputsValues, currency: currency })
                }}
              />
            )}
          </div> */}

          <div className={s.input}>
            <Input
              placeholder="0.0"
              label={data.elCost}
              min="0.001"
              step="0.01"
              max="1"
              type="number"
              value={inputsValues.elCost}
              error={inputsErrors.elCost ? "This field is required" : ""}
              onChange={e => {
                setResult({ ...result, fetched: false })
                setInputsValues({ ...inputsValues, elCost: e.target.value })
                setInputsError({ ...inputsErrors, elCost: false })
              }}
              onBlur={e => {
                e.target.value === ""
                  ? setInputsError({ ...inputsErrors, elCost: true })
                  : setInputsError({ ...inputsErrors, elCost: false })
              }}
              rightText={`${inputsValues.currency.value.currency}/kWh`}
            />
          </div>
          <div className={s.input}>
            <Input
              placeholder={data.price}
              label={data.price}
              min="0"
              step="100"
              type="number"
              value={inputsValues.price}
              error={inputsErrors.price ? "This field is required" : ""}
              onChange={e => {
                setResult({ ...result, fetched: false })
                setInputsValues({ ...inputsValues, price: e.target.value })
                setInputsError({ ...inputsErrors, price: false })
              }}
              onBlur={e => {
                e.target.value === ""
                  ? setInputsError({ ...inputsErrors, price: true })
                  : setInputsError({ ...inputsErrors, price: false })
              }}
              rightText={inputsValues.currency.value.currency}
            />
          </div>
        </div>

        <div className={s.buttonsWrapper}>
          <div className={s.buttonMain}>
            <Button
              onClick={() => calculate()}
              disabled={
                result.loading ||
                result.fetched ||
                inputsErrors.elCost ||
                inputsErrors.price ||
                result.error
              }
              theme={result.fetched ? "secondary" : ""}
            >
              {result.error
                ? data.buttons.unavailable
                : result.loading
                ? data.buttons.loading
                : data.buttons.main}
            </Button>
          </div>
          <button
            className={s.buttonClear}
            onClick={() => {
              setInputsValues(initialValues)
              setResult({ ...initialResult, fetchData: result.fetchData })
              setScrolling("top")
            }}
          >
            {data.buttons.clear}
          </button>
        </div>
        <div
          className={cx(s.results, { [s.active]: result.fetched })}
          ref={resultRef}
        >
          <div className={s.resultsInner}>
            <div className={s.header}>{data.result.header}</div>
            <div className={s.amount}>
              <p>
                {result.fetchData &&
                  (result.fetchData.profit * inputsValues.items).toFixed(2)}
              </p>{" "}
              {inputsValues.currency.value.currency}/
              <span>{data.result.day}</span>
            </div>
            <div className={s.payback}>
              {data.result.payback}{" "}
              <p>
                {result.fetchData &&
                  Math.floor(
                    (inputsValues.price || 0) / (result.fetchData.revenue || 0)
                  )}
              </p>{" "}
              {data.result.days}
            </div>
            <div className={s.buttonMain}>
              <Button
                link="https://marketplace.zionodes.com/farm-operators"
                external
              >
                {data.result.button}
              </Button>
            </div>
            <div className={s.exclaimer}>
              <div className={s.icon}>
                <img alt="Attention!" src={AttentionIcon} />
              </div>
              {data.result.attention}
              {` BTC/USD exchange rate = $${btcUsd.toLocaleString()}.`}
            </div>
          </div>
        </div>
      </div>
    </StyledCard>
  )
}

export default Calculator
