import React from "react"
import TradingDeskReview from "../../../ReviewsPage/ReviewsTradingDesk/TradingDeskReview/TradingDeskReview"

// Styles
import * as styles from "./ReviewsTradingDesk.module.sass"
import Button from "../../../../ui-kit/Button/Button"

const ReviewsTradingDesk = ({
  tradingDeskLink,
  reviewsTradingDesk,
  seeMoreBtn,
}) => {
  return (
    <div className={styles.content}>
      <div className={styles.reviewsBlock}>
        {[reviewsTradingDesk[0], reviewsTradingDesk[1]]
          .filter(Boolean)
          .map(review => (
            <TradingDeskReview
              key={review.id}
              reviewerName={review.reviewer_name}
              time={review.time}
              offerRating={review.offer_rating}
              sellerRating={review.seller_rating}
              seller={review.seller}
              text={review.text}
              reviewerPhoto={review.reviewer_photo}
            />
          ))}
      </div>

      <Button
        className={styles.seeMoreBtn}
        external
        link={tradingDeskLink}
        theme="small"
      >
        {seeMoreBtn}
      </Button>
    </div>
  )
}

export default ReviewsTradingDesk
