import React from "react"
import TrustPilotReview from "../../../ReviewsPage/ReviewsTrustPilot/TrustPilotReview/TrustPilotReview"

// Styles
import * as styles from "./ReviewsTrustPilot.module.sass"
import Button from "../../../../ui-kit/Button/Button"

const ReviewsTrustPilot = ({
  trustPilotLink,
  reviewsTrustpilot,
  seeMoreBtn,
}) => {
  return (
    <div className={styles.content}>
      <div className={styles.reviewsBlock}>
        {[reviewsTrustpilot[0], reviewsTrustpilot[1]]
          .filter(Boolean)
          .map(review => (
            <TrustPilotReview
              key={review.id}
              rating={review.rating}
              verified={review.verified}
              time={review.time}
              reviewerName={review.reviewer_name}
              title={review.title}
              text={review.text}
            />
          ))}
      </div>
      <Button
        className={styles.seeMoreBtn}
        external
        link={trustPilotLink}
        theme="small"
      >
        {seeMoreBtn}
      </Button>
    </div>
  )
}

export default ReviewsTrustPilot
