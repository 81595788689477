import React from "react"

// Styles
import * as styles from "./CryptoGeekRating.module.sass"

const CryptoGeekStarFilled = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
  >
    <path
      d="M14.8404 6.33333L11.4246 9.43617L12.3102 13.9291C12.3608 14.2021 12.1837 14.4504 11.9053 14.5C11.88 14.5 11.8294 14.5 11.8041 14.5C11.7282 14.5 11.627 14.4752 11.5511 14.4255L7.50273 12.1915L3.40375 14.4255C3.15073 14.5496 2.8471 14.4752 2.72059 14.227C2.66999 14.1277 2.64469 14.0035 2.66999 13.9043L3.58087 9.41135L0.165057 6.30851C-0.037362 6.10993 -0.0626643 5.81206 0.139754 5.61347C0.215661 5.53901 0.342173 5.46454 0.443382 5.46454L5.07371 4.91844L7.04729 0.77305C7.1232 0.599291 7.30031 0.5 7.50273 0.5C7.70515 0.5 7.88227 0.624113 7.95817 0.797872L9.93176 4.94326L14.5621 5.48936C14.8404 5.51418 15.0428 5.76241 14.9922 6.03546C14.9922 6.13475 14.9416 6.25886 14.8404 6.33333Z"
      fill="#EEB450"
    />
  </svg>
)

const CryptoGeekStarGray = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
  >
    <path
      d="M9.84391 5.6881L13.9077 6.16738L10.9203 8.88102C10.7258 9.05772 10.6379 9.32336 10.6888 9.58121L11.4654 13.5216L7.8651 11.5348C7.64077 11.411 7.36878 11.4103 7.14381 11.5329L3.51503 13.5107L4.31592 9.56037C4.36842 9.30138 4.28076 9.03388 4.08516 8.85619L1.0978 6.14256L5.16155 5.66328C5.41754 5.63309 5.64008 5.47356 5.75088 5.24083L7.49682 1.57359L9.25458 5.26566C9.36538 5.49838 9.58793 5.65791 9.84391 5.6881ZM0.443382 5.92297L0.48071 5.96102L0.443382 5.92297Z"
      stroke="#EEB450"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

const CryptoGeekRating = ({ rating }) => {
  const realRating = rating > 5 ? 5 : rating < 0 ? 0 : rating

  return (
    <div className={styles.cryptoGeekRatingWrapper}>
      {new Array(realRating).fill(0).map((_el, index) => (
        <CryptoGeekStarFilled key={index} />
      ))}

      {new Array(5 - realRating).fill(0).map((_el, index) => (
        <CryptoGeekStarGray key={index} />
      ))}

      <span className={styles.cryptoGeekRating}>{realRating.toFixed(1)}</span>
    </div>
  )
}

export default CryptoGeekRating
