import React from "react"
import dayjs from "dayjs"

import PlaceholderReseller from "../../../../icons/light/placeholders/placeholder-user-small.svg"
import PlaceholderResellerDark from "../../../../icons/dark/placeholders/placeholder-user-small.svg"

import * as styles from "./TradingDeskReview.module.sass"
import TradingDeskRating from "../../../../ui-kit/TradingDeskRating/TradingDeskRating"
import StyledCard from "../../../../ui-kit/StyledCard/StyledCard"
import { useCurrentTheme } from "../../../../utils/themeChanger"

const TradingDeskReview = ({
  reviewerName,
  time,
  offerRating,
  sellerRating,
  seller,
  text,
  reviewerPhoto,
}) => {
  const colorTheme = useCurrentTheme()
  const isLightTheme = colorTheme.data?.theme === "light"

  return (
    <div className={styles.reviewCardWrapper}>
      <StyledCard>
        <div className={styles.ratingBlock}>
          <div className={styles.info}>
            <div className={styles.left}>
              <div className={styles.imgWrapper}>
                {reviewerPhoto?.localFile?.publicURL ? (
                  <img
                    src={reviewerPhoto.localFile.publicURL}
                    alt={reviewerName}
                  />
                ) : (
                  <div className={styles.userImgPlaceholder}>
                    <img
                      src={
                        isLightTheme
                          ? PlaceholderReseller
                          : PlaceholderResellerDark
                      }
                    />
                  </div>
                )}
              </div>
              <div className={styles.userInfo}>
                <span className={styles.name}>Buyer</span>
                <span className={styles.username}>{reviewerName}</span>
                <span className={styles.since}>
                  {dayjs(time).format("YYYY-MM-DD")}
                </span>
              </div>
              <div className={styles.ratings}>
                <div className={styles.ratingItem}>
                  <span className={styles.ratingDescription}>
                    Offer rating:
                  </span>
                  <TradingDeskRating
                    rating={offerRating}
                    size="default"
                    ratingMode={false}
                  />
                </div>

                <div className={styles.ratingItem}>
                  <span className={styles.ratingDescription}>
                    Seller rating:
                  </span>
                  <TradingDeskRating
                    rating={sellerRating}
                    size="default"
                    ratingMode={false}
                  />
                </div>
              </div>
            </div>
          </div>
          {text && (
            <>
              <hr className={styles.line} />
              <div>
                <div>
                  <span className={styles.propertyName}>Review:</span>
                </div>
                <div>
                  <span className={styles.reviewText}>{text}</span>
                </div>
              </div>
            </>
          )}
        </div>
      </StyledCard>
    </div>
  )
}

export default TradingDeskReview
