import React, { useEffect, useLayoutEffect, useRef, useState } from "react"
import cx from "classnames"
// import dayjs from "dayjs"
import "dayjs/locale/zh-cn"
import { debounce } from "lodash"

// Components
import Container from "../../../ui-kit/Container/Container"
import Row from "../../../ui-kit/Row/Row"
import StyledCard from "../../../ui-kit/StyledCard/StyledCard"
import TotalHashChart from "../../TotalHashChart"
import SalesChart from "../../SalesChart"
import Select from "../../../ui-kit/Select/Select"

// Styles
import * as styles from "./real-time-data.module.sass"
import Heading from "../../../ui-kit/Heading/Heading"
import Section from "../../../ui-kit/Section/Section"
import { Tab, TabList, TabPanel, Tabs } from "react-tabs"
import { AnimateSharedLayout, motion } from "framer-motion"
import Calculator from "./Calculator/Calculator"
import LazyHydrate from "react-lazy-hydration"

// const tabs = ["Profit Estimates", "Total Hashrate"]

const DEFAULT_PRESET = {
  label: "Week",
  value: "week",
}
const DEFAULT_PRESET_ZH = {
  label: "周",
  value: "week",
}

const RealTimeData = ({ header, tabs, calculator, graphics, lang = "en" }) => {
  const [selected, setSelected] = useState(0)

  const salesRef = React.useRef(null)
  const hashRef = React.useRef(null)

  const [salesWidth, setSalesWidth] = React.useState(500)
  const [hashWidth, setHashWidth] = React.useState(300)
  const [hashrates, setHashrates] = React.useState([])
  const [hashPreset, setHashPreset] = React.useState(DEFAULT_PRESET)
  const [sales, setSales] = React.useState([])
  const [salesPreset, setSalesPreset] = React.useState(DEFAULT_PRESET)

  useEffect(() => {
    getHashrates(hashPreset.value, "all")
    window.addEventListener("resize", debounce(updateSize, 1000))
    return () => {
      window.addEventListener("resize", debounce(updateSize, 1000))
    }
  }, [])

  const getHashrates = async (range, type) => {}

  useEffect(() => {
    getHashrates(hashPreset.value, "hash")
  }, [hashPreset])

  useEffect(() => {
    getHashrates(salesPreset.value, "sales")
  }, [salesPreset])

  useLayoutEffect(() => {
    updateSize()
  }, [salesRef.current, hashRef.current, selected])

  const updateSize = () => {
    if (salesRef.current && hashRef.current) {
      setSalesWidth(salesRef.current.offsetWidth)
      setHashWidth(hashRef.current.offsetWidth)
    }
  }

  return (
    <Section className={styles.section}>
      <LazyHydrate ssrOnly>
        <Container size="small">
          <Row theme="flex-start">
            <Heading theme="left">
              <h2>{header}</h2>
            </Heading>
          </Row>
        </Container>
      </LazyHydrate>

      <LazyHydrate whenIdle>
        <Tabs
          className={styles.tabs}
          onSelect={index => selected !== index && setSelected(index)}
          forceRenderTabPanel={true}
        >
          <Container size="small">
            <Row theme="flex-start">
              <div className={styles.tabsListOuter}>
                <div className={styles.tabsListWrapper}>
                  <TabList className={styles.tabsList}>
                    <div className={styles.tabsListInner}>
                      <AnimateSharedLayout>
                        {tabs.map((el, index) => (
                          <Tab
                            className={styles.tabsItem}
                            selectedClassName={styles.active}
                            key={index}
                          >
                            {el}
                            {tabs[selected] === el && (
                              <motion.div
                                transition={spring}
                                layoutId="outline"
                                initial={false}
                                className={styles.border}
                              />
                            )}
                          </Tab>
                        ))}
                      </AnimateSharedLayout>
                    </div>
                  </TabList>
                </div>
              </div>
            </Row>
          </Container>

          <TabPanel>
            <Container size="small">
              <Row theme="flex-start">
                <Calculator data={calculator} lang={lang} />
              </Row>
            </Container>
          </TabPanel>
          <TabPanel>
            <Container>
              <Row>
                <div className={styles.chartsWrapper}>
                  <div ref={hashRef} className={styles.hashWrapper}>
                    <StyledCard>
                      <div className={styles.contentWrapper}>
                        <div className={styles.headerWrapper}>
                          <div className={styles.textWrapper}>
                            <span className={styles.label}>
                              {graphics.performance.header}
                            </span>
                            <span className={styles.title}>
                              {graphics.performance.subheader}
                            </span>
                          </div>
                          <div className={styles.selectWrapper}>
                            <Select
                              minified
                              isSearchable={false}
                              options={[
                                {
                                  label: "Week",
                                  value: "week",
                                },
                                {
                                  label: "Month",
                                  value: "month",
                                },
                                {
                                  label: "Year",
                                  value: "year",
                                },
                              ]}
                              value={hashPreset}
                              onChange={preset => setHashPreset(preset)}
                            />
                          </div>
                        </div>

                        <TotalHashChart
                          data={hashrates}
                          w={hashWidth}
                          h={242}
                        />
                      </div>
                    </StyledCard>
                  </div>
                  <div ref={salesRef} className={styles.salesWrapper}>
                    <StyledCard>
                      <div
                        className={cx(styles.contentWrapperSales, {
                          [styles.contentWrapperSalesEmpty]: sales.length !== 0,
                        })}
                      >
                        <div className={styles.headerWrapper}>
                          <div className={styles.textWrapper}>
                            <span className={styles.label}>
                              {graphics.salesValue.header}
                            </span>
                            <span className={styles.title}>
                              {graphics.salesValue.subheader}
                            </span>
                          </div>
                          <div className={styles.selectWrapper}>
                            <Select
                              minified
                              isSearchable={false}
                              options={[
                                {
                                  label: "Week",
                                  value: "week",
                                },
                                {
                                  label: "Month",
                                  value: "month",
                                },
                                {
                                  label: "Year",
                                  value: "year",
                                },
                              ]}
                              value={salesPreset}
                              onChange={preset => setSalesPreset(preset)}
                            />
                          </div>
                        </div>

                        <SalesChart
                          w={salesWidth}
                          h={260}
                          data={sales}
                          id="sales"
                        />
                      </div>
                    </StyledCard>
                  </div>
                </div>
              </Row>
            </Container>
          </TabPanel>
        </Tabs>
      </LazyHydrate>
    </Section>
  )
}

const spring = {
  type: "spring",
  stiffness: 500,
  damping: 30,
}

export default RealTimeData
