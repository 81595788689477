import React, { useEffect, useState } from "react"
import Img from "gatsby-image"
import classNames from "classnames"
import { debounce } from "lodash"

// Components
import Container from "../../../ui-kit/Container/Container"
import Row from "../../../ui-kit/Row/Row"
import Heading from "../../../ui-kit/Heading/Heading"
import ArrowButton from "../../../ui-kit/ArrowButton/ArrowButton"
import Section from "../../../ui-kit/Section/Section"

// Icons
import QuoteIcon from "../../../icons/light/testimonials/quote.svg"
import TwitterIcon from "../../../icons/light/testimonials/twitter.svg"
import MaskIcon from "../../../icons/light/testimonials/Substract2.svg"
import Mask2Icon from "../../../icons/light/testimonials/Subtract.svg"

import QuoteIconDark from "../../../icons/dark/testimonials/quote.svg"
import TwitterIconDark from "../../../icons/dark/testimonials/twitter.svg"
import MaskIconDark from "../../../icons/dark/testimonials/Substract2.svg"
import Mask2IconDark from "../../../icons/dark/testimonials/Subtract.svg"

// Styles
import * as styles from "./testimonials.module.sass"
import LazyHydrate from "react-lazy-hydration"

import { useCurrentTheme } from "../../../utils/themeChanger"

const Testimonials = ({ data, header, previousButton, nextButton }) => {
  const colorTheme = useCurrentTheme()
  const isLightTheme = colorTheme.data?.theme === "light"

  const [currentPair, setCurrentPair] = useState({
    current: 0,
    firstElement: data[0],
    secondElement: data[1],
  })

  const [active, setActive] = useState({
    firstStage: false,
    opacityAll: false,
    opacityImage: false,
    finalStage: false,
  })

  const [disabled, setDisabled] = useState(false)

  const animation = () => {
    if (!disabled) {
      setDisabled(true)
      setActive(active => ({
        ...active,
        firstStage: true,
      }))
      setTimeout(() => {
        setActive(active => ({
          ...active,
          opacityAll: true,
        }))
        setTimeout(() => {
          setActive(active => ({
            ...active,
            opacityImage: true,
          }))
          setTimeout(() => {
            setActive(active => ({
              ...active,
              finalStage: true,
            }))
            setTimeout(() => {
              setCurrentPair(currentPair => ({
                ...currentPair,
                firstElement: data[currentPair.current],
                secondElement: data[currentPair.current + 1],
              }))
              setActive(active => ({
                ...active,
                finalStage: false,
              }))
              setTimeout(() => {
                setActive(active => ({
                  ...active,
                  opacityImage: false,
                }))
                setTimeout(() => {
                  setActive(active => ({
                    ...active,
                    opacityAll: false,
                  }))
                  setTimeout(() => {
                    setActive(active => ({
                      ...active,
                      firstStage: false,
                    }))
                    setDisabled(false)
                  }, 300)
                }, 300)
              }, 300)
            }, 600)
          }, 300)
        }, 300)
      }, 300)
    }
  }

  const toggleTestimonialsNext = () => {
    if (windowWidth > 768) {
      if (data[currentPair.current + 2]) {
        if (data[currentPair.current + 3]) {
          setCurrentPair({ ...currentPair, current: currentPair.current + 2 })
        } else {
          setCurrentPair({ ...currentPair, current: currentPair.current + 1 })
        }
      } else {
        setCurrentPair({ ...currentPair, current: 0 })
      }
    } else {
      if (data[currentPair.current + 1]) {
        setCurrentPair({ ...currentPair, current: currentPair.current + 1 })
      } else {
        setCurrentPair({ ...currentPair, current: 0 })
      }
    }
    animation()
  }

  const toggleTestimonialsPrevious = () => {
    if (windowWidth > 768) {
      if (data[currentPair.current - 1]) {
        if (data[currentPair.current - 2]) {
          setCurrentPair({ ...currentPair, current: currentPair.current - 2 })
        } else {
          setCurrentPair({ ...currentPair, current: currentPair.current - 1 })
        }
      } else {
        setCurrentPair({ ...currentPair, current: data.length - 2 })
      }
    } else {
      if (data[currentPair.current - 1]) {
        setCurrentPair({ ...currentPair, current: currentPair.current - 1 })
      } else {
        setCurrentPair({ ...currentPair, current: data.length - 1 })
      }
    }
    animation()
  }

  const [windowWidth, setWindowWidth] = useState()

  useEffect(() => {
    const debouncedHandleResize = debounce(function handleResize() {
      if (windowWidth <= 768) {
        if (window.innerWidth > 768) {
          if (currentPair.current === data.length - 1) {
            setCurrentPair({
              firstElement: data[data.length - 2],
              secondElement: data[data.length - 1],
              current: currentPair.current - 1,
            })
          }
        }
      }
      setWindowWidth(window.innerWidth)
    }, 1000)

    debouncedHandleResize()

    window.addEventListener("resize", debouncedHandleResize)

    return () => {
      window.removeEventListener("resize", debouncedHandleResize)
    }
  })

  let buttons

  if (data.length > 1) {
    if (data.length > 2 || windowWidth < 768) {
      buttons = (
        <div className={styles.buttons}>
          <div className={styles.button}>
            <ArrowButton
              disabled={disabled}
              onClick={() => toggleTestimonialsPrevious()}
              content={previousButton}
            />
          </div>
          <div className={styles.button}>
            <ArrowButton
              disabled={disabled}
              type="right"
              onClick={() => toggleTestimonialsNext()}
              content={nextButton}
            />
          </div>
        </div>
      )
    }
  }

  return (
    <>
      <Section className={styles.section}>
        <Container>
          <Row>
            <LazyHydrate ssrOnly>
              <Heading>
                <h2>{header}</h2>
              </Heading>
            </LazyHydrate>
            <LazyHydrate whenIdle>
              <div className={styles.content}>
                <div className={styles.card}>
                  <div className={styles.cardInner}>
                    <div
                      className={classNames(styles.image, {
                        [styles.transform]: active.firstStage,
                        [styles.active]: active.opacityImage,
                      })}
                    >
                      <div
                        className={classNames(styles.imageInner, {
                          [styles.opacity]: active.finalStage,
                          [styles.reverse]:
                            currentPair.firstElement.image_style ===
                            "angle_top",
                        })}
                      >
                        <Img
                          alt="Testimonial author photo"
                          fluid={
                            currentPair.firstElement.image.localFile
                              .childImageSharp.fluid
                          }
                        />
                      </div>
                      <div
                        className={classNames(styles.backgroundIcon, {
                          [styles.active]: active.opacityImage,
                        })}
                      >
                        {currentPair.firstElement.image_style ===
                        "angle_top" ? (
                          <img
                            alt="Mask"
                            src={isLightTheme ? MaskIcon : MaskIconDark}
                          />
                        ) : (
                          <img
                            alt="Mask"
                            src={isLightTheme ? Mask2Icon : Mask2IconDark}
                          />
                        )}
                      </div>
                    </div>
                    <div>
                      <div
                        className={classNames(styles.cardStyle, {
                          [styles.active]: active.firstStage,
                        })}
                      />
                      <div
                        className={classNames(styles.inner, {
                          [styles.active]: active.firstStage,
                          [styles.opacity]: active.opacityAll,
                        })}
                      >
                        <div
                          className={classNames(styles.icon, {
                            [styles.active]: active.firstStage,
                          })}
                        >
                          <img
                            alt="quote"
                            src={isLightTheme ? QuoteIcon : QuoteIconDark}
                          />
                        </div>
                        <div
                          className={classNames(
                            styles.heading,
                            currentPair.firstElement.id
                          )}
                        >
                          <h3>{currentPair.firstElement.name}</h3>
                          <a
                            href={currentPair.firstElement.link}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              alt="Twitter"
                              src={isLightTheme ? TwitterIcon : TwitterIconDark}
                            />
                          </a>
                        </div>
                        <h4>{currentPair.firstElement.position}</h4>
                        <p className={styles.date}>
                          {currentPair.firstElement.date}
                        </p>
                        {!active.opacityAll && (
                          <p className={classNames(styles.comment)}>
                            {currentPair.firstElement.testimonial}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                {windowWidth > 768 && (
                  <div className={styles.card}>
                    <div className={styles.cardInner}>
                      <div
                        className={classNames(styles.image, {
                          [styles.transform]: active.firstStage,
                          [styles.active]: active.opacityImage,
                        })}
                      >
                        <div
                          className={classNames(styles.imageInner, {
                            // [styles.opacity]: active.opacityImage,
                            [styles.opacity]: active.finalStage,
                            [styles.reverse]:
                              currentPair.secondElement.image_style ===
                              "angle_top",
                          })}
                        >
                          <Img
                            alt="Testimonial author photo"
                            className={styles.iconImage}
                            fluid={
                              currentPair.secondElement.image.localFile
                                .childImageSharp.fluid
                            }
                          />
                        </div>
                        <div
                          className={classNames(styles.backgroundIcon, {
                            [styles.active]: active.opacityImage,
                          })}
                        >
                          {currentPair.secondElement.image_style ===
                          "angle_top" ? (
                            <img
                              alt="Mask"
                              src={isLightTheme ? MaskIcon : MaskIconDark}
                            />
                          ) : (
                            <img
                              alt="Mask"
                              src={isLightTheme ? Mask2Icon : Mask2IconDark}
                            />
                          )}
                        </div>
                      </div>
                      <div>
                        <div
                          className={classNames(styles.cardStyle, {
                            [styles.active]: active.firstStage,
                          })}
                        />
                        <div
                          className={classNames(styles.inner, {
                            [styles.active]: active.firstStage,
                            [styles.opacity]: active.opacityAll,
                          })}
                        >
                          <div
                            className={classNames(styles.icon, {
                              [styles.active]: active.firstStage,
                            })}
                          >
                            <img
                              alt="quote"
                              src={isLightTheme ? QuoteIcon : QuoteIconDark}
                            />
                          </div>
                          <div
                            className={classNames(
                              styles.heading,
                              currentPair.secondElement.id
                            )}
                          >
                            <h3>{currentPair.secondElement.name}</h3>
                            <a
                              href={currentPair.secondElement.link}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                alt="Twitter"
                                src={
                                  isLightTheme ? TwitterIcon : TwitterIconDark
                                }
                              />
                            </a>
                          </div>
                          <h4>{currentPair.secondElement.position}</h4>
                          <p
                            className={classNames(
                              styles.date,
                              currentPair.secondElement.id
                            )}
                          >
                            {currentPair.secondElement.date}
                          </p>
                          {!active.opacityAll && (
                            <p
                              className={classNames(
                                styles.comment,
                                currentPair.secondElement.id
                              )}
                            >
                              {currentPair.secondElement.testimonial}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {buttons}
            </LazyHydrate>
          </Row>
        </Container>
      </Section>
    </>
  )
}

export default Testimonials
