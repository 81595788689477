import React from "react"

import * as s from "./Rating.module.sass"

const StarsReview = ({ rating = 0 }) => {
  const getPercentageRating = val => 17 * val

  return (
    <div className={s.ratingWrapper}>
      <div className={s.starWrapper}>
        <div className={s.starSprite}>
          <span
            style={{ width: `${getPercentageRating(rating)}px` }}
            className={s.starRating}
          />
        </div>
      </div>
      <div className={s.ratingNumber}>{Number(rating).toFixed(1)}</div>
    </div>
  )
}

export default StarsReview
