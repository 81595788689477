import React from "react"
import * as styles from "./counter-btn.module.sass"

const CounterBtn = ({ icon, onClick, disabled = false }) => (
  <button
    type="button"
    className={styles.btn}
    onClick={onClick}
    disabled={disabled}
  >
    <div className={styles.content}>{icon}</div>
  </button>
)

export default CounterBtn
