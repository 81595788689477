// extracted by mini-css-extract-plugin
export var animation = "CertifiedSellersPlaceholder-module--animation--1y56M";
export var avatar = "CertifiedSellersPlaceholder-module--avatar--sDF92";
export var block = "CertifiedSellersPlaceholder-module--block--G9fOl";
export var bottom = "CertifiedSellersPlaceholder-module--bottom--5-5-5";
export var coming = "CertifiedSellersPlaceholder-module--coming--NkMMM";
export var line = "CertifiedSellersPlaceholder-module--line--PUA+X";
export var load = "CertifiedSellersPlaceholder-module--load--QbBX2";
export var middle = "CertifiedSellersPlaceholder-module--middle--nn7U0";
export var nameStar = "CertifiedSellersPlaceholder-module--nameStar--JeLVI";
export var star = "CertifiedSellersPlaceholder-module--star--xAn30";
export var top = "CertifiedSellersPlaceholder-module--top--b1Q0Q";