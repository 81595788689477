// extracted by mini-css-extract-plugin
export var imgWrapper = "TradingDeskReview-module--imgWrapper--SiseV";
export var info = "TradingDeskReview-module--info--NFFTw";
export var left = "TradingDeskReview-module--left--SkCHL";
export var line = "TradingDeskReview-module--line--ggsGV";
export var name = "TradingDeskReview-module--name--F4Hzf";
export var propertyBlock = "TradingDeskReview-module--propertyBlock--SXBNn";
export var propertyName = "TradingDeskReview-module--propertyName--FjV9l";
export var propertyValue = "TradingDeskReview-module--propertyValue--nNt+f";
export var ratingBlock = "TradingDeskReview-module--ratingBlock--902BO";
export var ratingDescription = "TradingDeskReview-module--ratingDescription--tCLIj";
export var ratingItem = "TradingDeskReview-module--ratingItem--zRwiI";
export var ratingSchema = "TradingDeskReview-module--ratingSchema--Yx9hI";
export var ratings = "TradingDeskReview-module--ratings--ZgwQ-";
export var reviewCardWrapper = "TradingDeskReview-module--reviewCardWrapper--Tms0Y";
export var reviewText = "TradingDeskReview-module--reviewText--SZB1G";
export var since = "TradingDeskReview-module--since--DD-8f";
export var userImgPlaceholder = "TradingDeskReview-module--userImgPlaceholder--Uw3CD";
export var userInfo = "TradingDeskReview-module--userInfo--o9WrJ";
export var username = "TradingDeskReview-module--username--Dlwz-";