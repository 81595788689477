import React, { useState } from "react"
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"
import "react-tabs/style/react-tabs.css"
import { motion, AnimateSharedLayout } from "framer-motion"

// Components
import Container from "../../../ui-kit/Container/Container"
import Row from "../../../ui-kit/Row/Row"
import Heading from "../../../ui-kit/Heading/Heading"
import VerifiedBlock from "./VerifiedDatatCenters/VerifiedDatatCenters"
import CertifiedSellers from "./CertifiedSellers/CertifiedSellers"
import DiscountedHardware from "./DiscountedHardware/DiscountedHardware"

// Styles
import * as styles from "./marketplace-offers.module.sass"
import Section from "../../../ui-kit/Section/Section"
import LazyHydrate from "react-lazy-hydration"

const MarketplaceOffers = ({
  header,
  tabs,
  verifiedBlock,
  discountedHardware,
  certifiedSellers,
  lang,
  offersDatacenters,
  offersDiscountedHardware,
  offersCertifiedSellers,
  coming,
}) => {
  const [selected, setSelected] = useState(0)

  return (
    <Section className={styles.section}>
      <div className="content">
        <Container>
          <Row>
            <LazyHydrate ssrOnly>
              <Heading theme="left">
                <h2>{header}</h2>
              </Heading>
            </LazyHydrate>
            <LazyHydrate whenIdle>
              <Tabs
                className={styles.tabs}
                onSelect={index => selected !== index && setSelected(index)}
              >
                <div className={styles.tabsListOuter}>
                  <div className={styles.tabsListWrapper}>
                    <TabList className={styles.tabsList}>
                      <div className={styles.tabsListInner}>
                        <AnimateSharedLayout>
                          {tabs.map(el => (
                            <Tab
                              className={styles.tabsItem}
                              selectedClassName={styles.active}
                              key={el}
                            >
                              {el}
                              {tabs[selected] === el && (
                                <motion.div
                                  transition={spring}
                                  layoutId="outline"
                                  initial={false}
                                  className={styles.border}
                                />
                              )}
                            </Tab>
                          ))}
                        </AnimateSharedLayout>
                      </div>
                    </TabList>
                  </div>
                </div>

                <TabPanel>
                  <VerifiedBlock
                    data={offersDatacenters}
                    headers={verifiedBlock}
                    lang={lang}
                  />
                </TabPanel>
                <TabPanel>
                  <DiscountedHardware
                    data={offersDiscountedHardware}
                    headers={discountedHardware}
                    lang={lang}
                  />
                </TabPanel>
                <TabPanel>
                  <CertifiedSellers
                    data={offersCertifiedSellers}
                    headers={certifiedSellers}
                    lang={lang}
                  />
                </TabPanel>
              </Tabs>
            </LazyHydrate>
          </Row>
        </Container>
      </div>
    </Section>
  )
}

const spring = {
  type: "spring",
  stiffness: 500,
  damping: 30,
}

export default MarketplaceOffers
