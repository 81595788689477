import { useMediaQuery } from "react-responsive"

export const useTabletOrThinner = (settings = {}, device, callback) =>
  useMediaQuery(
    {
      ...settings,
      maxWidth: 768,
    },
    device,
    callback
  )
