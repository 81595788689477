// extracted by mini-css-extract-plugin
export var avatar = "VerifiedDatatCenters-module--avatar--Ehst4";
export var avatarImage = "VerifiedDatatCenters-module--avatarImage--guEfB";
export var avatarPlaceholder = "VerifiedDatatCenters-module--avatarPlaceholder--QbWAq";
export var block = "VerifiedDatatCenters-module--block--9aHhu";
export var bottom = "VerifiedDatatCenters-module--bottom--gRde6";
export var button = "VerifiedDatatCenters-module--button--oGqk1";
export var card = "VerifiedDatatCenters-module--card--H-hEi";
export var container = "VerifiedDatatCenters-module--container--BX1Cc";
export var line = "VerifiedDatatCenters-module--line--fF8y6";
export var middle = "VerifiedDatatCenters-module--middle--yPeIq";
export var nameStar = "VerifiedDatatCenters-module--nameStar--1ETxc";
export var reviews = "VerifiedDatatCenters-module--reviews--rttUK";
export var star = "VerifiedDatatCenters-module--star--OB-M5";
export var top = "VerifiedDatatCenters-module--top--w-aOO";
export var youtubeLink = "VerifiedDatatCenters-module--youtubeLink--kK4T2";