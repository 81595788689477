const applyDropShadow = defs => {
  const filter = defs
    .append("filter")
    .attr("id", "dropshadow")
    .attr("color-interpolation-filters", "sRGB")
    .attr("filterUnits", "objectBoundingBox")
    .attr("x", 0)
    .attr("y", 0)
    .attr("width", "100%")
    .attr("height", "100%")

  filter
    .append("feFlood")
    .attr("flood-opacity", 0)
    .attr("result", "BackgroundImageFix")
  filter
    .append("feBlend")
    .attr("mode", "normal")
    .attr("in", "SourceGraphic")
    .attr("in2", "BackgroundImageFix")
    .attr("result", "shape")
  filter
    .append("feColorMatrix")
    .attr("in", "SourceAlpha")
    .attr("type", "matrix")
    .attr("values", "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 50 0")
    .attr("result", "hardAlpha")
  filter.append("feOffset").attr("dx", "-4").attr("dy", "-4")
  filter.append("feGaussianBlur").attr("stdDeviation", "3.5")
  filter
    .append("feComposite")
    .attr("in2", "hardAlpha")
    .attr("operator", "arithmetic")
    .attr("k2", "-1")
    .attr("k3", "1")
  filter
    .append("feColorMatrix")
    .attr("type", "matrix")
    .attr(
      "values",
      "0 0 0 0 0.357778 0 0 0 0 0.501563 0 0 0 0 0.766667 0 0 0 1 0"
    )
  filter
    .append("feBlend")
    .attr("mode", "normal")
    .attr("in2", "shape")
    .attr("result", "effect1_innerShadow")
  filter
    .append("feColorMatrix")
    .attr("in", "SourceAlpha")
    .attr("type", "matrix")
    .attr("values", "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0")
    .attr("result", "hardAlpha")
  filter.append("feOffset").attr("dx", "4").attr("dy", "4")
  filter.append("feGaussianBlur").attr("stdDeviation", "3.5")
  filter
    .append("feComposite")
    .attr("in2", "hardAlpha")
    .attr("operator", "arithmetic")
    .attr("k2", "-1")
    .attr("k3", "1")
  filter
    .append("feColorMatrix")
    .attr("type", "matrix")
    .attr(
      "values",
      "0 0 0 0 0.00534722 0 0 0 0 0.116287 0 0 0 0 0.320833 0 0 0 1 0"
    )
  filter
    .append("feBlend")
    .attr("mode", "normal")
    .attr("in2", "effect1_innerShadow")
    .attr("result", "effect2_innerShadow")

  const filterLight = defs
    .append("filter")
    .attr("id", "dropshadowLight")
    .attr("filterUnits", "userSpaceOnUse")
    .attr("color-interpolation-filters", "sRGB")

  filterLight
    .append("feFlood")
    .attr("flood-opacity", 0)
    .attr("result", "BackgroundImageFix")

  filterLight
    .append("feBlend")
    .attr("mode", "normal")
    .attr("in", "SourceGraphic")
    .attr("in2", "BackgroundImageFix")
    .attr("result", "shape")
  filterLight
    .append("feColorMatrix")
    .attr("in", "SourceAlpha")
    .attr("type", "matrix")
    .attr("values", "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0")
    .attr("result", "hardAlpha")
  filterLight.append("feOffset").attr("dx", "-4").attr("dy", "-4")
  filterLight.append("feGaussianBlur").attr("stdDeviation", "3.5")
  filterLight
    .append("feComposite")
    .attr("in2", "hardAlpha")
    .attr("operator", "arithmetic")
    .attr("k2", "-1")
    .attr("k3", "1")
  filterLight
    .append("feColorMatrix")
    .attr("type", "matrix")
    .attr(
      "values",
      "0 0 0 0 0.357778 0 0 0 0 0.501563 0 0 0 0 0.766667 0 0 0 1 0"
    )
  filterLight
    .append("feBlend")
    .attr("mode", "normal")
    .attr("in2", "shape")
    .attr("result", "effect1_innerShadow")
  filterLight
    .append("feColorMatrix")
    .attr("in", "SourceAlpha")
    .attr("type", "matrix")
    .attr("values", "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0")
    .attr("result", "hardAlpha")
  filterLight.append("feOffset").attr("dx", "4").attr("dy", "4")
  filterLight.append("feGaussianBlur").attr("stdDeviation", "3.5")
  filterLight
    .append("feComposite")
    .attr("in2", "hardAlpha")
    .attr("operator", "arithmetic")
    .attr("k2", "-1")
    .attr("k3", "1")
  filterLight
    .append("feColorMatrix")
    .attr("type", "matrix")
    .attr(
      "values",
      "0 0 0 0 0.00534722 0 0 0 0 0.116287 0 0 0 0 0.320833 0 0 0 0.35 0"
    )
  filterLight
    .append("feBlend")
    .attr("mode", "normal")
    .attr("in2", "effect1_innerShadow")
    .attr("result", "effect2_innerShadow")

  const barLight = defs
    .append("filter")
    .attr("id", "barLight")
    .attr("filterUnits", "userSpaceOnUse")
    .attr("color-interpolation-filters", "sRGB")

  barLight
    .append("feFlood")
    .attr("flood-opacity", 0)
    .attr("result", "BackgroundImageFix")
  barLight
    .append("feBlend")
    .attr("mode", "normal")
    .attr("in", "SourceGraphic")
    .attr("in2", "BackgroundImageFix")
    .attr("result", "shape")
  barLight
    .append("feColorMatrix")
    .attr("in", "SourceAlpha")
    .attr("type", "matrix")
    .attr("values", "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0")
    .attr("result", "hardAlpha")

  barLight.append("feOffset").attr("dx", "2").attr("dy", "2")

  barLight.append("feGaussianBlur").attr("stdDeviation", "5")

  barLight
    .append("feComposite")
    .attr("in2", "hardAlpha")
    .attr("operator", "arithmetic")
    .attr("k2", "-1")
    .attr("k3", "1")

  barLight
    .append("feColorMatrix")
    .attr("type", "matrix")
    .attr(
      "values",
      "0 0 0 0 0.241528 0 0 0 0 0.432243 0 0 0 0 0.783333 0 0 0 1 0"
    )

  barLight
    .append("feBlend")
    .attr("mode", "normal")
    .attr("in2", "shape")
    .attr("result", "effect1_innerShadow")

  barLight
    .append("feColorMatrix")
    .attr("in", "SourceAlpha")
    .attr("result", "hardAlpha")
    .attr("values", "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0")

  barLight
    .append("feOffset")
    .attr("dx", "-2")
    .attr("dy", "-2")
    .attr("values", "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0")

  barLight.append("feGaussianBlur").attr("stdDeviation", "3.5")

  barLight
    .append("feComposite")
    .attr("in2", "hardAlpha")
    .attr("in2", "hardAlpha")
    .attr("operator", "arithmetic")
    .attr("k2", "-1")
    .attr("k3", "1")

  barLight
    .append("feColorMatrix")
    .attr("type", "matrix")
    .attr("values", "0 0 0 0 0.7875 0 0 0 0 0.8623 0 0 0 0 1 0 0 0 1 0")

  barLight
    .append("feBlend")
    .attr("mode", "normal")
    .attr("in2", "effect1_innerShadow")
    .attr("result", "effect2_innerShadow")

  const liteLinearGradient = defs
    .append("linearGradient")
    .attr("id", "barLiteGradient")
    .attr("x1", "-5.46875")
    .attr("y1", "-1.19524e-06")
    .attr("x2", "9.11031")
    .attr("y2", "41.7253")
    .attr("gradientUnits", "userSpaceOnUse")

  liteLinearGradient.append("stop").attr("stop-color", "#6F9BEC")

  liteLinearGradient
    .append("stop")
    .attr("offset", "1")
    .attr("stop-color", "white")

  const darkLinearGradient = defs
    .append("linearGradient")
    .attr("id", "darkLinearGradient")

    .attr("x1", "-1.61565")
    .attr("x2", "14.4458")
    .attr("y1", "100%")
    .attr("y2", "100%")
    .attr("gradientUnits", "userSpaceOnUse")

  darkLinearGradient.append("stop").attr("stop-color", "#092964")

  darkLinearGradient
    .append("stop")
    .attr("offset", "1")
    .attr("stop-color", "white")

  const dark2LinearGradient = defs
    .append("linearGradient")
    .attr("id", "dark2LinearGradient")
    .attr("gradientUnits", "userSpaceOnUse")
  dark2LinearGradient.append("stop").attr("stop-color", "#092964")

  dark2LinearGradient
    .append("stop")
    .attr("stop-color", "#163878")
    .attr("stop-opacity", "0.99")
    .attr("offset", "1")
}
export default applyDropShadow
