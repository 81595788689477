import React from "react"

// Components
import Container from "../../../ui-kit/Container/Container"
import Row from "../../../ui-kit/Row/Row"
import StyledCard from "../../../ui-kit/StyledCard/StyledCard"

// Styles
import * as styles from "./future-of-blockchain.module.sass"
import Heading from "../../../ui-kit/Heading/Heading"
import Section from "../../../ui-kit/Section/Section"
import ReadMore from "../../../icons/light/InThePress/arrow.svg"

import { useCurrentTheme } from "../../../utils/themeChanger"
import LazyHydrate from "react-lazy-hydration"

const futureOfBlockchainImagesAspectRatio = [
  { width: 68, height: 68 },
  { width: 68, height: 69 },
  { width: 78, height: 68 },
  { width: 70, height: 70 },
]

const FutureOfBlockchain = ({ data, header, button }) => {
  const colorTheme = useCurrentTheme()
  const isLightTheme = colorTheme.data?.theme === "light"

  return (
    <Section>
      <LazyHydrate ssrOnly>
        <Container>
          <Heading>
            <h2 dangerouslySetInnerHTML={{ __html: header }} />
          </Heading>
          <Row>
            <div className={styles.cards}>
              {data.map((blockchain, index) => (
                <div className={styles.card} key={blockchain.id}>
                  <StyledCard theme={"reverse"} link={blockchain.link} external>
                    <div className={styles.inner}>
                      <div className={styles.icon}>
                        <StyledCard theme={"reverse circle"}>
                          <div className={styles.iconContent}>
                            <img
                              height={
                                futureOfBlockchainImagesAspectRatio[index]
                                  .height
                              }
                              width={
                                futureOfBlockchainImagesAspectRatio[index].width
                              }
                              src={
                                isLightTheme
                                  ? blockchain.icon.localFile.publicURL
                                  : blockchain.icon_dark.localFile.publicURL
                              }
                              alt={blockchain.title}
                            />
                          </div>
                        </StyledCard>
                      </div>

                      <h3>{blockchain.title}</h3>
                      <p>{blockchain.description}</p>

                      {blockchain.link && (
                        <p className={styles.Link}>
                          {button} <img alt="Read more" src={ReadMore} />
                        </p>
                      )}
                    </div>
                  </StyledCard>
                </div>
              ))}
            </div>
          </Row>
        </Container>
      </LazyHydrate>
    </Section>
  )
}

export default FutureOfBlockchain
