import React from "react"

// Components
// import CounterBtn from "./counterBtn/CounterBtn"

// Icons
import Plus from "../../icons/light/Plus.svg"
import Minus from "../../icons/light/Minus.svg"

import PlusDark from "../../icons/dark/Plus.svg"
import MinusDark from "../../icons/dark/Minus.svg"

// Styles
import * as styles from "./plus-minus-input.module.sass"
import CounterBtn from "../CounterBtn/CounterBtn"

import { useCurrentTheme } from "../../utils/themeChanger"

const PlusMinusInput = ({
  value,
  minValue,
  maxValue,
  onChange,
  item,
  items,
}) => {
  const colorTheme = useCurrentTheme()
  const isLightTheme = colorTheme.data?.theme === "light"

  const changeAmount = direction => {
    if (direction === "up") {
      onChange(value + 1)
    } else if (direction === "bottom") {
      if (value - 1 > 0) {
        onChange(value - 1)
      }
    }
  }

  return (
    <div className={styles.counterBlock}>
      <div>
        <CounterBtn
          disabled={minValue ? value <= minValue : false}
          onClick={() => changeAmount("bottom")}
          icon={<img src={isLightTheme ? Minus : MinusDark} alt="Minus" />}
        />
      </div>
      <div className={styles.amount}>
        <span>{value}</span>
      </div>
      <div>
        <CounterBtn
          disabled={maxValue ? value >= maxValue : false}
          onClick={() => changeAmount("up")}
          icon={<img src={isLightTheme ? Plus : PlusDark} alt="Plus" />}
        />
      </div>
      <div className={styles.label}>
        <span>{value === 1 ? item : items}</span>
      </div>
    </div>
  )
}

export default PlusMinusInput
