import React from "react"

// Components
import Button from "../../../../ui-kit/Button/Button"
import StyledCard from "../../../../ui-kit/StyledCard/StyledCard"
import DiscountedPlaceholder from "./DiscountedHardwarePlaceholder/DiscountedHardwarePlaceholder"

// Styles
import * as styles from "./DiscountedHardware.module.sass"
import PlaceholderAsic from "../../../../icons/light/placeholders/placeholder-asic.svg"
import Img from "gatsby-image"

const DiscountedHardware = ({ data, headers, lang = "en" }) => {
  let content = []

  // if (data.loading) {
  //   content.push(
  //     <>
  //       <div className={styles.card}>
  //         <DiscountedPlaceholder />
  //       </div>
  //       <div className={styles.card}>
  //         <DiscountedPlaceholder />
  //       </div>
  //       <div className={styles.card}>
  //         <DiscountedPlaceholder />
  //       </div>
  //     </>
  //   )
  // }

  data
    .reduce((acc, el) => {
      acc[acc.length - 1] && acc[acc.length - 1].length < 2
        ? acc[acc.length - 1].push(el)
        : acc.push([el])

      return acc
    }, [])
    .map(asics =>
      content.push(
        <div className={styles.cardWrapper} key={asics[0].id}>
          {asics.map(asic => (
            <div className={styles.card} key={asic.id}>
              <StyledCard>
                <div className={styles.block}>
                  <div className={styles.top}>
                    <div className={styles.image}>
                      {asic.image ? (
                        <div className={styles.avatarImage}>
                          <Img
                            fluid={asic.image.localFile.childImageSharp.fluid}
                            alt={asic.model}
                          />
                        </div>
                      ) : (
                        <div className={styles.imagePlaceholder}>
                          <img alt="Asic" src={PlaceholderAsic} />
                        </div>
                      )}
                      {/*<Img fluid={data.placeholderImage.childImageSharp.fluid} />*/}
                    </div>
                    <div className={styles.nameStar}>
                      <h3>{asic.model}</h3>
                    </div>
                  </div>

                  <div className={styles.nameStarData}>
                    <div className={styles.line}>
                      <h4>{`${headers.hashrateOnline}:`}</h4>{" "}
                      <p>{asic.nominal_hashrate} Th/s</p>
                    </div>
                    <div className={styles.line}>
                      <h4>{`${headers.hashrateReference}:`}</h4>{" "}
                      <p>{asic.declared_hashrate} Th/s</p>
                    </div>
                    <div className={styles.line}>
                      <h4>{`${headers.power}:`}</h4>{" "}
                      <p>{asic.nominal_consumption} W</p>
                    </div>
                    <div className={styles.line}>
                      <h4>{`${headers.items}:`}</h4> <p>{asic.items}</p>
                    </div>
                  </div>
                  <h4 className={styles.price}>
                    {headers.prices}: {asic.max_price} - {asic.min_price} USDT
                  </h4>
                  <div className={styles.bottom}>
                    <div className={styles.button}>
                      <Button link={asic.link} external>
                        {headers.buy}
                      </Button>
                    </div>
                  </div>
                </div>
              </StyledCard>
            </div>
          ))}
        </div>
      )
    )

  if (data.length < 4) {
    for (let i = 0; i < 4 - data.length; i++) {
      content.push(
        <div className={styles.card} key={`discounted-hardware-${i}`}>
          <DiscountedPlaceholder comingSoon={"Coming soon..."} />
        </div>
      )
    }
  }

  return <div className={styles.container}>{content}</div>
}

export default DiscountedHardware
