import React, { useState, useEffect } from "react"
import cx from "classnames"
import Typewriter from "typewriter-effect"

// Components
import Container from "../../../../ui-kit/Container/Container"
import Button from "../../../../ui-kit/Button/Button"
import { useTabletOrThinner } from "../../../../utils/mediaQuery"
import LazyHydrate from "react-lazy-hydration"

import * as styles from "./FirstScreenDark.module.sass"

const FirstScreenDark = ({
  title,
  description,
  goToMarketplace,
  goToMarketplaceBtn,
  goToTradingDesk,
  goToTradingDeskBtn,
}) => {
  const isTabletOrThinner = useTabletOrThinner()

  const [isSpanish, setIsSpanish] = useState(false)

  useEffect(() => {
    if (window.location.href.includes("es.zionodes.com")) {
      setIsSpanish(true)
    }
  }, [])

  return (
    <div className={styles.wrapper}>
      <img
        src={"/media/images/bg.webp"}
        className={styles.background}
        alt="Zionodes"
      />
      <Container>
        <div className={styles.container}>
          <h1>
            <LazyHydrate whenIdle>
              <span>
                <Typewriter
                  options={{
                    strings: isSpanish
                      ? [
                          "Comprar",
                          "Vender",
                          "Anfitrión",
                          "Tokenizar",
                          "Intercambio",
                        ]
                      : ["Buy", "Sell", "Host", "Tokenize", "Swap"],
                    autoStart: true,
                    loop: true,
                  }}
                />
              </span>{" "}
              ASICs
            </LazyHydrate>
          </h1>

          <LazyHydrate ssrOnly>
            <h2>{title}</h2>
            <p>{description}</p>

            <div className={styles.directivesContainer}>
              <div className={styles.directive}>
                <h3 className={styles.directiveTitle}>{goToMarketplace}</h3>
                <div className={styles.button}>
                  <Button
                    theme={cx({
                      ["small"]: isTabletOrThinner,
                    })}
                    link="https://marketplace.zionodes.com"
                    external
                  >
                    {goToMarketplaceBtn}
                  </Button>
                </div>
              </div>
              <div className={styles.directive}>
                <h3 className={styles.directiveTitle}>{goToTradingDesk}</h3>
                <div className={styles.button}>
                  <Button
                    theme={cx("secondary", {
                      ["small"]: isTabletOrThinner,
                    })}
                    link="https://trade.zionodes.com/"
                    external
                  >
                    {goToTradingDeskBtn}
                  </Button>
                </div>
              </div>
            </div>
          </LazyHydrate>
        </div>
      </Container>
    </div>
  )
}

export default FirstScreenDark
