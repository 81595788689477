// extracted by mini-css-extract-plugin
export var animation = "DiscountedHardwarePlaceholder-module--animation--Cydu8";
export var block = "DiscountedHardwarePlaceholder-module--block--DJ2fS";
export var bottom = "DiscountedHardwarePlaceholder-module--bottom--MbKah";
export var coming = "DiscountedHardwarePlaceholder-module--coming--dA3Z0";
export var desVersion = "DiscountedHardwarePlaceholder-module--desVersion--IeusD";
export var image = "DiscountedHardwarePlaceholder-module--image--FPvwt";
export var left = "DiscountedHardwarePlaceholder-module--left--R4mcu";
export var line = "DiscountedHardwarePlaceholder-module--line--gwZLd";
export var load = "DiscountedHardwarePlaceholder-module--load--CGE9R";
export var middle = "DiscountedHardwarePlaceholder-module--middle--dkLA0";
export var mobVersion = "DiscountedHardwarePlaceholder-module--mobVersion--54v3o";
export var nameStar = "DiscountedHardwarePlaceholder-module--nameStar--8UXqD";
export var price = "DiscountedHardwarePlaceholder-module--price--RncwT";
export var right = "DiscountedHardwarePlaceholder-module--right--nTykg";
export var top = "DiscountedHardwarePlaceholder-module--top--kO3U3";