// extracted by mini-css-extract-plugin
export var active = "calculator-module--active--y7kHS";
export var amount = "calculator-module--amount--rUnJs";
export var buttonClear = "calculator-module--buttonClear--YZfOK";
export var buttonMain = "calculator-module--buttonMain--6JXpY";
export var buttonsWrapper = "calculator-module--buttonsWrapper--tOqWb";
export var content = "calculator-module--content--3W3KI";
export var contentCol = "calculator-module--contentCol--Rp2zo";
export var exclaimer = "calculator-module--exclaimer--X2-A7";
export var header = "calculator-module--header--6yGwu";
export var icon = "calculator-module--icon--9TBEf";
export var input = "calculator-module--input--ec9U4";
export var itemsInput = "calculator-module--itemsInput--KX0Zb";
export var itemsLabel = "calculator-module--itemsLabel--dDs3z";
export var itemsWrapper = "calculator-module--itemsWrapper--lhJha";
export var payback = "calculator-module--payback--LZku4";
export var results = "calculator-module--results--20eeB";
export var resultsInner = "calculator-module--resultsInner--+2BAm";