import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import YAMLGlobal from "../content/global.yaml"
import YAMLContent from "../content/homePage.yaml"
import { useCurrentTheme } from "../utils/themeChanger"

// Components
import Layout from "../components/layout"
import SEO from "../components/seo"
import Benefits from "../components/HomePage/Benefits/Benefits"
import FutureOfBlockchain from "../components/HomePage/FutureOfBlockchain/FutureOfBlockchain"
import MarketplaceOffers from "../components/HomePage/MarketplaceOffers/MarketplaceOffers"
import Testimonials from "../components/HomePage/Testimonials/Testimonials"
import Subscription from "../components/HomePage/Subscription/Subscription"
import Video from "../components/HomePage/Video/Video"
import Reviews from "../components/HomePage/Reviews/Reviews"
import FirstScreenLight from "../components/HomePage/FirstScreen/FirstScreenLight/FirstScreenLight"
import FirstScreenDark from "../components/HomePage/FirstScreen/FirstScreenDark/FirstScreenDark"
import RealTimeData from "../components/HomePage/RealTimeData/RealTimeData"
import LazyHydrate from "react-lazy-hydration"

const IndexPage = ({ location }) => {
  const colorTheme = useCurrentTheme()
  const isLightTheme = colorTheme.data?.theme === "light"

  const data = useStaticQuery(graphql`
    query {
      home: directusHomeEng {
        seo_title
        seo_description
        seo_keywords
        title
        description
        benfs {
          id
          title
          description
          icon {
            localFile {
              publicURL
            }
          }
          icon_dark {
            localFile {
              publicURL
            }
          }
        }
        testimos {
          id
          link
          name
          position
          date
          testimonial
          image_style
          image {
            localFile {
              childImageSharp {
                fluid(maxWidth: 200) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        offers_datacenters {
          id
          first_name
          last_name
          rating
          reviews_count
          created_at
          total_hashrate
          m12_price_monthly_electricity_cost
          m2_price_monthly_electricity_cost
          available_hardware
          items_on_zionodes
          youtube_link
          image {
            localFile {
              childImageSharp {
                fluid(maxWidth: 200) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        offers_hardware {
          id
          model
          nominal_hashrate
          declared_hashrate
          nominal_consumption
          items
          max_price
          min_price
          link
          image {
            localFile {
              childImageSharp {
                fluid(maxWidth: 200) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        futs {
          id
          title
          description
          link
          icon {
            localFile {
              publicURL
            }
          }
          icon_dark {
            localFile {
              publicURL
            }
          }
        }
        how_it_works_image {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1800) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        video_link
        subscription_description
        subscription_attention
      }
      ambassadors: allDirectusAmbassadorsNetwork {
        edges {
          node {
            id
            name
            country
            city
            twitter
            meet_up
            longitude
            latitude
            photo {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 300) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
      certified_sellers: allDirectusCertifiedSeller {
        edges {
          node {
            id
            first_name
            last_name
            rating
            reviews_count
            created_at
            items_in_stock
            items_sold
            link
          }
        }
      }
      reviews: allDirectusReview {
        nodes {
          seo_title
          seo_keywords
          seo_description

          header_title
          header_description
          header_email
          header_ceo
          header_ceo_photo {
            localFile {
              childImageSharp {
                fluid(maxWidth: 300) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          header_ceo_position

          linkedin_link

          trustpilot_title
          trustpilot_link
          reviews_trustpilot {
            id
            rating
            verified
            time
            reviewer_name
            title
            text
          }

          cryptogeek_title
          cryptogeek_link
          reviews_cryptogeek {
            id
            rating
            time
            reviewer_name
            text
          }

          trading_desk_title
          trading_desk_link
          reviews_trading_desk {
            id
            reviewer_name
            time
            offer_rating
            seller_rating
            seller
            text
          }
        }
      }
    }
  `)

  const seoTitle = data.home.seo_title
  const seoDescription = data.home.seo_description
  const seoKeywords = data.home.seo_keywords

  const firstTitle = data.home.title
  const firstDescription = data.home.description

  const benefits = data.home.benfs

  const video = data.home.video_link

  const testimonials = data.home.testimos

  const futureOfBlockchain = data.home.futs

  const reviewsData = data.reviews.nodes[0]

  const subscriptionDescription = data.home.subscription_description
  const subscriptionAttention = data.home.subscription_attention

  const offersDatacenters = data.home.offers_datacenters

  const offersDiscountedHardware = data.home.offers_hardware

  const offersCertifiedSellers = data.certified_sellers.edges

  return (
    <Layout location={location}>
      {isLightTheme ? (
        <FirstScreenLight
          title={firstTitle}
          description={firstDescription}
          goToMarketplace={YAMLGlobal.goToMarketplace}
          goToMarketplaceBtn={YAMLGlobal.goToMarketplaceBtn}
          goToTradingDesk={YAMLGlobal.goToTradingDesk}
          goToTradingDeskBtn={YAMLGlobal.goToTradingDeskBtn}
        />
      ) : (
        <FirstScreenDark
          title={firstTitle}
          description={firstDescription}
          goToMarketplace={YAMLGlobal.goToMarketplace}
          goToMarketplaceBtn={YAMLGlobal.goToMarketplaceBtn}
          goToTradingDesk={YAMLGlobal.goToTradingDesk}
          goToTradingDeskBtn={YAMLGlobal.goToTradingDeskBtn}
        />
      )}

      {isLightTheme && (
        <LazyHydrate whenIdle>
          <Video
            header={YAMLContent.video.header}
            button={YAMLContent.video.button}
            videoContent={video}
          />
        </LazyHydrate>
      )}

      <MarketplaceOffers
        offersDatacenters={offersDatacenters}
        offersDiscountedHardware={offersDiscountedHardware}
        offersCertifiedSellers={offersCertifiedSellers}
        header={YAMLContent.offers.header}
        tabs={YAMLContent.offers.tabs}
        verifiedBlock={YAMLContent.offers.verifiedBlock}
        discountedHardware={YAMLContent.offers.discountedHardware}
        certifiedSellers={YAMLContent.offers.certifiedSellers}
      />

      <Reviews
        header={YAMLContent.reviews.header}
        tabs={YAMLContent.reviews.tabs}
        seeMoreTradingDesk={YAMLContent.reviews.seeMoreTradingDesk}
        seeMoreTrustPilot={YAMLContent.reviews.seeMoreTrustPilot}
        seeMoreCryprogeek={YAMLContent.reviews.seeMoreCryprogeek}
        reviewsData={reviewsData}
      />

      <Benefits
        data={benefits}
        header={YAMLContent.benefits.header}
        skip={YAMLGlobal.skip}
      />

      <RealTimeData
        header={YAMLContent.realTimeData.header}
        tabs={YAMLContent.realTimeData.tabs}
        calculator={YAMLContent.realTimeData.calculator}
        graphics={YAMLContent.realTimeData.graphics}
      />

      <Testimonials
        data={testimonials}
        header={YAMLContent.testimonials.header}
        previousButton={YAMLGlobal.previous}
        nextButton={YAMLGlobal.next}
      />

      <FutureOfBlockchain
        data={futureOfBlockchain}
        header={YAMLContent.futureOfBlockchain.header}
        button={YAMLGlobal.readMore}
      />

      <Subscription
        description={subscriptionDescription}
        attention={subscriptionAttention}
        header={YAMLContent.subscription.header}
        email={YAMLContent.subscription.email}
        button={YAMLGlobal.subscribe}
      />

      <SEO
        title={seoTitle || firstTitle}
        description={seoDescription || firstDescription}
        keywords={seoKeywords}
      />
    </Layout>
  )
}

export default IndexPage
