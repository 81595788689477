// extracted by mini-css-extract-plugin
export var avatarImage = "DiscountedHardware-module--avatarImage--NzOeB";
export var block = "DiscountedHardware-module--block--i645E";
export var bottom = "DiscountedHardware-module--bottom---NtIo";
export var button = "DiscountedHardware-module--button--0Zvdz";
export var card = "DiscountedHardware-module--card--ZBAi5";
export var cardWrapper = "DiscountedHardware-module--cardWrapper--Yag7D";
export var container = "DiscountedHardware-module--container--r9ROJ";
export var desVersion = "DiscountedHardware-module--desVersion--lf5Fy";
export var image = "DiscountedHardware-module--image--z6dBr";
export var imagePlaceholder = "DiscountedHardware-module--imagePlaceholder--2zIms";
export var item = "DiscountedHardware-module--item---Rrhb";
export var left = "DiscountedHardware-module--left--3oFo+";
export var line = "DiscountedHardware-module--line--uxOZp";
export var middle = "DiscountedHardware-module--middle--nxrof";
export var nameStar = "DiscountedHardware-module--nameStar--SwfCF";
export var nameStarData = "DiscountedHardware-module--nameStarData--UxfJc";
export var price = "DiscountedHardware-module--price--KHGzA";
export var right = "DiscountedHardware-module--right--xjseC";
export var top = "DiscountedHardware-module--top--dgL6q";