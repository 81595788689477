import React, { useRef, useLayoutEffect } from "react"
import * as d3 from "d3"
import * as s from "./sales-chart.module.sass"
import { Scrollbars } from "react-custom-scrollbars"

const renderThumb = ({ style, ...props }) => {
  const thumbStyle = {
    background: "#163878",
    boxShadow: "-4px -4px 7px #FFFFFF, 5px 5px 7px #DAE0E9",
    borderRadius: "100px",
    width: "5px",
  }
  return <div style={{ ...style, ...thumbStyle }} {...props} />
}

const SalesChart = ({ data, w, h, id }) => {
  const d3Container = useRef(null)
  const d3YAxis = useRef(null)
  const scrollBar = useRef(null)

  const margin = {
    top: 30,
    right: 30,
    bottom: 30,
    left: 30,
  }
  const coeff = data.length / 4
  const widthCoef = coeff > 1 ? coeff : 0.8
  const width = w * widthCoef
  const height = h

  useLayoutEffect(() => {
    renderChart()
    return () => {
      d3.selectAll(`${id}-svg`).exit().remove()
    }
  }, [data, w, d3Container.current])

  const renderChart = () => {
    scrollBar.current && scrollBar.current.scrollToRight()

    d3.selectAll(`.${id}-svg`).exit().remove()
    d3.selectAll(`.xAxis`).remove()
    d3.selectAll(`.yAxis`).remove()

    d3.selectAll(`.lineArea`).remove()
    d3.selectAll(`.line`).remove()

    const svg = d3
      .select(d3Container.current)
      .attr("height", h)
      .attr("class", `${id}-svg`)
      .attr("overflow", "scroll")

    if (data.length && d3Container.current) {
      const yMax = 1.2 * d3.max(data, d => d.value)
      const getXAxisPadding = () => {
        if (yMax >= 900000) return 65
        if (yMax > 100000) return 55
        if (yMax > 10000) return 55
        if (yMax > 1000) return 40
        if (yMax > 1000) return 15
        if (yMax > 100) return 30
        return 25
      }

      const yAxisSvg = d3
        .select(d3YAxis.current)
        .attr("width", getXAxisPadding())
        .attr("transform", "translate(0,-30)")
        .attr("height", height + 20)

      const x = d3
        .scaleBand()
        .rangeRound([width - margin.right, 0])
        .domain(data.map(d => `${d.date}`))

      const yTemp = d3.scaleLinear().range([height, 0]).domain([0, yMax]).nice()
      const yAxisT = d3.axisLeft(yTemp).ticks(5)
      const yTicks = yAxisT.scale().ticks(5)
      const yPad = ticks => {
        if (ticks === 6) return 40

        if (ticks < 5) return 25
        return 50
      }
      const y = d3
        .scaleLinear()
        .range([height, yPad(yTicks.length)])
        .domain([0, yMax])
        .nice()
      const yAxis = d3.axisLeft(y).ticks(5)

      const xTicks = x.domain()
      const xAxis = d3.axisBottom(x).tickValues(xTicks)

      yTicks.map((yVal, i) => {
        const newY = i * (height / yTicks.length)
        const calcY = height - newY
        return (
          yVal > -1 &&
          svg
            .append("line")
            .attr("stroke-width", 1)
            .attr("x1", 0)
            .attr("x2", width)
            .attr("y1", calcY)
            .attr("y2", calcY)
            .attr("class", "line")
            .attr("transform", `translate(20,${-margin.top})`)
            .attr("stroke-width", "1")
            .attr("stroke", "#d8dff6")
            .attr("fill", "#092964")
        )
      })

      svg
        .append("g")
        .attr("class", "xAxis")
        .attr("transform", `translate(0,${height - margin.bottom})`)
        .attr("stroke-width", 0)
        .call(xAxis)
        .selectAll("text")
        .attr("font-size", "12")
        .attr("fill", "#8F9BB2")
        .attr("transform", "translate(0,0)")
        .style("text-anchor", "middle")

      yAxisSvg
        .append("g")
        .attr("class", "yAxis")
        .attr("stroke-width", 0)
        .call(yAxis)
        .selectAll("text")
        .attr("font-size", "12")
        .attr("fill", "#8F9BB2")
        .attr("class", "yAxisValue")
        .attr("stroke-width", 0)
        .attr("transform", `translate(${getXAxisPadding()},0)`)

      const linePadding = 5
      const line = d3
        .line()
        .x(d => x(d.date) + linePadding) // set the x values for the line generator
        .y(d => y(d.value)) // set the y values for the line generator
        .curve(d3.curveMonotoneX)

      const path = svg
        .append("path")
        .attr("filter", "url(#lineFilter)")
        .datum(data)
        .attr("class", "line")
        .attr("fill", "none")
        .attr("stroke", "#163878")
        .attr("stroke-width", 3)
        .attr("stroke-linejoin", "round")
        .attr("stroke-linecap", "round")
        .attr("width", width)
        .attr("height", height)
        .attr(
          "transform",
          `translate(${x.bandwidth() / 2 - linePadding},${-25})`
        )
        .attr("d", line)

      const node = path.node()
      if (node !== null) {
        const pathLength = node.getTotalLength()
        path
          .attr("stroke-dasharray", `${pathLength} ${pathLength}`)
          .attr("stroke-dashoffset", pathLength)
          .transition()
          .duration(750)
          .ease(d3.easeLinear)
          .attr("stroke-dashoffset", 0)
      }

      const lineArea = d3
        .area()
        .curve(d3.curveMonotoneX)
        .x(d => x(d.date))
        .y0(d => y(d.value))
        .y1(() => height)

      svg
        .append("path")
        .datum(data)
        .attr("transform", `translate(${x.bandwidth() / 2},-25)`)
        .attr("fill", "url(#mygrad)")
        .attr("stroke", "none")
        .attr("stroke-width", 0)
        .attr("width", width)
        .attr("stroke-linejoin", "square")
        .attr("stroke-linecap", "square")
        .attr("class", "lineArea")

        .attr("d", lineArea)

      const lg = svg
        .append("defs")
        .append("linearGradient")
        .attr("filterUnits", "userSpaceOnUse")
        .attr("id", "mygrad")
        .attr("x1", 0)
        .attr("y1", 0)
        .attr("x2", 0)
        .attr("y2", 1)
      lg.append("stop")
        .attr("offset", "0%")
        .style("stop-color", "rgba(22, 56, 120, 0.74)")
        .style("stop-opacity", 0.7)

      lg.append("stop")
        .attr("offset", "100%")
        .style("stop-color", "rgba(22, 56, 120, 0)")
        .style("stop-opacity", 1)

      const defs = svg.append("defs")

      const filter = defs
        .append("filter")
        .attr("id", "lineFilter")
        .attr("x", "0")
        .attr("y", "0")
        .attr("filterUnits", "userSpaceOnUse")
        .attr("color-interpolation-filters", "sRGB")

      filter
        .append("feFlood")
        .attr("flood-opacity", 0)
        .attr("result", "BackgroundImageFix")
      filter
        .append("feBlend")
        .attr("mode", "normal")
        .attr("in", "SourceGraphic")
        .attr("in2", "BackgroundImageFix")
        .attr("result", "shape")
      filter
        .append("feColorMatrix")
        .attr("in", "SourceAlpha")
        .attr("type", "matrix")
        .attr("values", "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0")
      filter.append("feOffset").attr("dx", "1").attr("dy", "1")
      filter.append("feGaussianBlur").attr("stdDeviation", "1.5")
      filter
        .append("feComposite")
        .attr("in2", "hardAlpha")
        .attr("operator", "arithmetic")
        .attr("k2", "-1")
        .attr("k3", "1")
      filter
        .append("feColorMatrix")
        .attr("type", "matrix")
        .attr(
          "values",
          "0 0 0 0 0.0293576 0 0 0 0 0.147829 0 0 0 0 0.370833 0 0 0 0.56 0"
        )
      filter
        .append("feBlend")
        .attr("mode", "normal")
        .attr("in2", "BackgroundImageFix")
        .attr("result", "effect1_innerShadow")

      filter
        .append("feColorMatrix")
        .attr("in", "SourceAlpha")
        .attr("type", "matrix")
        .attr("values", "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0")
        .attr("result", "hardAlpha")
      filter.append("feOffset").attr("dx", "-1").attr("dy", "-1")
      filter.append("feGaussianBlur").attr("stdDeviation", "1.5")

      filter
        .append("feColorMatrix")
        .attr("in", "SourceAlpha")
        .attr("type", "matrix")
        .attr("values", "0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0")
      filter
        .append("feBlend")
        .attr("mode", "normal")
        .attr("in2", "effect1_innerShadow")
        .attr("result", "effect2_innerShadow")
      filter
        .append("feBlend")
        .attr("mode", "normal")
        .attr("in", "SourceGraphic")
        .attr("in2", "effect2_dropShadow")
        .attr("result", "shape")

      filter
        .append("feColorMatrix")
        .attr("in", "SourceAlpha")
        .attr("type", "matrix")
        .attr("values", "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0")
        .attr("result", "hardAlpha")
      filter.append("feOffset").attr("dx", "-1").attr("dy", "-1")
      filter.append("feGaussianBlur").attr("stdDeviation", "0.5")

      filter
        .append("feComposite")
        .attr("in2", "hardAlpha")
        .attr("operator", "arithmetic")
        .attr("k2", "-1")
        .attr("k3", "1")
      filter
        .append("feColorMatrix")
        .attr("type", "matrix")
        .attr(
          "values",
          "0 0 0 0 0.0265625 0 0 0 0 0.164796 0 0 0 0 0.425 0 0 0 1 0"
        )
      filter
        .append("feBlend")
        .attr("mode", "normal")
        .attr("in2", "shape")
        .attr("result", "effect3_innerShadow")

      filter
        .append("feColorMatrix")
        .attr("in", "SourceAlpha")
        .attr("type", "matrix")
        .attr("values", "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0")
        .attr("result", "hardAlpha")
      filter.append("feOffset").attr("dx", "1").attr("dy", "1")
      filter.append("feGaussianBlur").attr("stdDeviation", "0.5")

      filter
        .append("feComposite")
        .attr("in2", "hardAlpha")
        .attr("operator", "arithmetic")
        .attr("k2", "-1")
        .attr("k3", "1")
      filter
        .append("feColorMatrix")
        .attr("type", "matrix")
        .attr("values", "0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.46 0")
      filter
        .append("feBlend")
        .attr("mode", "normal")
        .attr("in2", "effect3_innerShadow")
        .attr("result", "effect4_innerShadow")

      svg.append("use").attr("xlink:href", ".yAxis")
      svg.append("use").attr("xlink:href", ".yAxisValue")
      svg.append("use").attr("xlink:href", ".line")
      svg.append("use").attr("xlink:href", ".lineArea")
    }
  }

  return data.length ? (
    <div className={s.chartWrapper}>
      <div>
        <svg ref={d3YAxis} />
      </div>
      <Scrollbars
        autoHeightMax="262px"
        autoHeight
        renderThumbHorizontal={style => renderThumb(style)}
        renderTrackVertical={props => (
          <div {...props} style={{ display: "none" }} />
        )}
        ref={scrollBar}
      >
        <div className={s.lineWrapper} style={{ width, height: `${h}px` }}>
          {w > 0 && (
            <svg
              id="my"
              ref={d3Container}
              width={`${width}`}
              height={h}
              overflow={"hidden"}
            />
          )}
        </div>
      </Scrollbars>
    </div>
  ) : (
    <div className={s.textWrapper}>
      <span className={s.text}>No data available</span>
    </div>
  )
}
export default SalesChart
