// extracted by mini-css-extract-plugin
export var active = "video-module--active--J6Cqd";
export var block = "video-module--block--mspaN";
export var button = "video-module--button--R7Cue";
export var buttonWrapper = "video-module--buttonWrapper--VZb5l";
export var icon = "video-module--icon--kb2SY";
export var text = "video-module--text--UUa1o";
export var video = "video-module--video--wqDAX";
export var videoImage = "video-module--videoImage--CbV1+";
export var videoInner = "video-module--videoInner--aIpHG";
export var visible = "video-module--visible--AqnBu";