// extracted by mini-css-extract-plugin
export var active = "real-time-data-module--active--v0A1d";
export var border = "real-time-data-module--border--Ry4sb";
export var chartsWrapper = "real-time-data-module--chartsWrapper--kCPtz";
export var contentWrapper = "real-time-data-module--contentWrapper--dCVQY";
export var contentWrapperSales = "real-time-data-module--contentWrapperSales--JUutH";
export var contentWrapperSalesEmpty = "real-time-data-module--contentWrapperSalesEmpty--zOCdV";
export var hashWrapper = "real-time-data-module--hashWrapper--0fhQb";
export var headerWrapper = "real-time-data-module--headerWrapper--EJ4Rp";
export var label = "real-time-data-module--label--eQ8c6";
export var salesWrapper = "real-time-data-module--salesWrapper--FIwFr";
export var section = "real-time-data-module--section--uGBuT";
export var selectWrapper = "real-time-data-module--selectWrapper--vN8QZ";
export var tabs = "real-time-data-module--tabs--5nBYQ";
export var tabsItem = "real-time-data-module--tabsItem--GJO2G";
export var tabsList = "real-time-data-module--tabsList--Ny7oM";
export var tabsListInner = "real-time-data-module--tabsListInner--SZDDB";
export var tabsListOuter = "real-time-data-module--tabsListOuter--EjWwb";
export var tabsListWrapper = "real-time-data-module--tabsListWrapper--roJ8O";
export var textWrapper = "real-time-data-module--textWrapper--qbZ2D";
export var title = "real-time-data-module--title--ud5we";