import React, { useRef, useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import classNames from "classnames"
import Img from "gatsby-image"

// Components
import Container from "../../../ui-kit/Container/Container"
import Row from "../../../ui-kit/Row/Row"
import Heading from "../../../ui-kit/Heading/Heading"

// Icons
import TriangleIcon from "../../../icons/light/video/triangle.svg"

// Styles
import * as styles from "./video.module.sass"

const Video = ({ header, button, videoContent }) => {
  const [visibility, setVisibility] = useState(true)

  const [videoFrame, setVideoFrame] = useState()

  const togglePlaceholder = () => {
    setVisibility(false)
    setVideoFrame(
      <iframe
        src={`${videoContent}&autoplay=1`}
        frameBorder="0"
        allow="autoplay; fullscreen"
        allowFullScreen
      />
    )
  }

  return (
    <div className={styles.block}>
      <Container size="small">
        <Row>
          <Heading>
            <h2>{header}</h2>
          </Heading>

          <div className={styles.video}>
            <div className={styles.videoInner}>{videoFrame}</div>
            <div
              className={classNames(styles.visible, {
                [styles.active]: !visibility,
              })}
              onClick={() => togglePlaceholder()}
              role="button"
            >
              <img
                alt="How it works"
                className={styles.videoImage}
                src={"/media/images/HowItWorks.webp"}
              />
              <div className={styles.buttonWrapper}>
                <div className={styles.button}>
                  <span className={styles.text}>
                    <span>{button}</span>
                  </span>
                  <span className={styles.icon}>
                    <img alt="Start" src={TriangleIcon} />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </Row>
      </Container>
    </div>
  )
}

export default Video
