import React from "react"
import dayjs from "dayjs"
import Img from "gatsby-image"

import { useCurrentTheme } from "../../../../utils/themeChanger"
// Components
import StyledCard from "../../../../ui-kit/StyledCard/StyledCard"
import Button from "../../../../ui-kit/Button/Button"
import CertifiedSellersPlaceholder from "./CertifiedSellersPlaceholder/CertifiedSellersPlaceholder"
import StarsReview from "../Rating/Ratting"

//Img
import PlaceholderReseller from "../../../../icons/light/placeholders/placeholder-reseller.svg"
import ReviewCount from "../../../../icons/light/reviewsCount.svg"
import ReviewCountDark from "../../../../icons/dark/reviewsCount.svg"

// Styles
import * as styles from "./CertifiedSellers.module.sass"

const CertifiedSellers = ({ headers, lang = "en", data }) => {
  const colorTheme = useCurrentTheme()
  const isLightTheme = colorTheme.data?.theme === "light"

  const content = []

  // if (data.loading) {
  //   content.push(
  //     <>
  //       <div className={styles.card}>
  //         <CertifiedSellersPlaceholder />
  //       </div>
  //       <div className={styles.card}>
  //         <CertifiedSellersPlaceholder />
  //       </div>
  //       <div className={styles.card}>
  //         <CertifiedSellersPlaceholder />
  //       </div>
  //     </>
  //   )
  // }

  data.map(({ node: reseller }) => {
    content.push(
      <div className={styles.card} key={reseller.id}>
        <StyledCard>
          <div className={styles.block}>
            <div className={styles.top}>
              <div className={styles.avatar}>
                {reseller.image ? (
                  <div className={styles.avatarImage}>
                    <Img
                      fluid={reseller.image.localFile.childImageSharp.fluid}
                      alt={`${reseller.first_name} ${reseller.last_name}`}
                    />
                  </div>
                ) : (
                  <div className={styles.avatarPlaceholder}>
                    <img
                      alt={`${reseller.first_name} ${reseller.last_name}`}
                      src={PlaceholderReseller}
                    />
                  </div>
                )}
              </div>
              <div className={styles.nameStar}>
                <h3>
                  {reseller.first_name} {reseller.last_name}
                </h3>
                <div className={styles.star}>
                  <StarsReview rating={reseller.rating} />
                  <div className={styles.reviews}>
                    <img
                      alt="Reviews"
                      src={isLightTheme ? ReviewCount : ReviewCountDark}
                    />
                    {reseller.reviews_count}
                  </div>
                </div>
                <div className={styles.line}>
                  <h4>{headers.onboarded}: </h4>{" "}
                  <p>{dayjs(reseller.created_at).format("MMMM DD, YYYY")}</p>
                </div>
              </div>
            </div>
            <div className={styles.middle}>
              <div className={styles.line}>
                <h4>{headers.itemsInStock}:</h4>{" "}
                <p>{reseller.items_in_stock}</p>
              </div>
              <div className={styles.line}>
                <h4>{headers.itemsSold}:</h4> <p>{reseller.items_sold}</p>
              </div>
            </div>
            <div className={styles.bottom}>
              <div className={styles.button}>
                <Button link={reseller.link} external>
                  {headers.contact}
                </Button>
              </div>
            </div>
          </div>
        </StyledCard>
      </div>
    )
  })

  if (data.length < 2) {
    for (let i = 0; i < 2 - data.length; i++) {
      content.push(
        <div className={styles.card} key={`certified-sellers-${i}`}>
          <CertifiedSellersPlaceholder comingSoon={"Coming soon..."} />
        </div>
      )
    }
  }

  return <div className={styles.container}>{content}</div>
}

export default CertifiedSellers
