// extracted by mini-css-extract-plugin
export var blueStarWrapper = "Rating-module--blueStarWrapper--sx2Lh";
export var emptyRatings = "Rating-module--emptyRatings--TsVb9";
export var fillRatings = "Rating-module--fillRatings--1vAx-";
export var fillStarMask = "Rating-module--fillStarMask--ub45P";
export var large = "Rating-module--large--HLl38";
export var mask = "Rating-module--mask--UJvX7";
export var ratingNumber = "Rating-module--ratingNumber--7YHDK";
export var ratingWrapper = "Rating-module--ratingWrapper--yybD4";
export var starMask = "Rating-module--starMask--0aC2N";
export var starRating = "Rating-module--starRating--b9xAe";
export var starRatingBig = "Rating-module--starRatingBig--S7nc-";
export var starRatings = "Rating-module--starRatings--yrt2W";
export var starSprite = "Rating-module--starSprite--IKXaj";
export var starSpriteBig = "Rating-module--starSpriteBig--Wkhn9";
export var starWrapper = "Rating-module--starWrapper--ArS7s";
export var starWrapperBig = "Rating-module--starWrapperBig--oZBrs";