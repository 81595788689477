// extracted by mini-css-extract-plugin
export var avatar = "CertifiedSellers-module--avatar--nujCO";
export var avatarImage = "CertifiedSellers-module--avatarImage--zFgm7";
export var avatarPlaceholder = "CertifiedSellers-module--avatarPlaceholder--djrI9";
export var block = "CertifiedSellers-module--block--ptSG7";
export var bottom = "CertifiedSellers-module--bottom--atG4u";
export var button = "CertifiedSellers-module--button--KPWqz";
export var card = "CertifiedSellers-module--card--mvX2A";
export var container = "CertifiedSellers-module--container--cxqpZ";
export var line = "CertifiedSellers-module--line--ycTnh";
export var middle = "CertifiedSellers-module--middle--rm7l+";
export var nameStar = "CertifiedSellers-module--nameStar--m3QYc";
export var reviews = "CertifiedSellers-module--reviews--H8htE";
export var star = "CertifiedSellers-module--star--T3Shk";
export var top = "CertifiedSellers-module--top--xs3wT";