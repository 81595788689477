import React, { useState } from "react"
import ReviewsTrustPilot from "./ReviewsTrustPilot/ReviewsTrustPilot"
import ReviewsTradingDesk from "./ReviewsTradingDesk/ReviewsTradingDesk"
import ReviewsCryptoGeek from "./ReviewsCryptoGeek/ReviewsCryptoGeek"
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"
import "react-tabs/style/react-tabs.css"
import { motion, AnimateSharedLayout } from "framer-motion"

// Components
import Container from "../../../ui-kit/Container/Container"
import Row from "../../../ui-kit/Row/Row"
import Heading from "../../../ui-kit/Heading/Heading"

// Styles
import * as styles from "./reviews.module.sass"
import Section from "../../../ui-kit/Section/Section"
import LazyHydrate from "react-lazy-hydration"

const Reviews = ({
  header,
  tabs,
  reviewsData,
  seeMoreTradingDesk,
  seeMoreTrustPilot,
  seeMoreCryprogeek,
}) => {
  const [selected, setSelected] = useState(0)

  return (
    <Section className={styles.section}>
      <div className="content">
        <Container>
          <Row>
            <LazyHydrate ssrOnly>
              <Heading theme="left">
                <h2>{header}</h2>
              </Heading>
            </LazyHydrate>
            <LazyHydrate whenIdle>
              <Tabs
                className={styles.tabs}
                onSelect={index => selected !== index && setSelected(index)}
              >
                <div className={styles.tabsListOuter}>
                  <div className={styles.tabsListWrapper}>
                    <TabList className={styles.tabsList}>
                      <div className={styles.tabsListInner}>
                        <AnimateSharedLayout>
                          {tabs.map(el => (
                            <Tab
                              className={styles.tabsItem}
                              selectedClassName={styles.active}
                              key={el}
                            >
                              {el}
                              {tabs[selected] === el && (
                                <motion.div
                                  transition={spring}
                                  layoutId="outline"
                                  initial={false}
                                  className={styles.border}
                                />
                              )}
                            </Tab>
                          ))}
                        </AnimateSharedLayout>
                      </div>
                    </TabList>
                  </div>
                </div>

                <TabPanel>
                  <ReviewsTradingDesk
                    tradingDeskLink={reviewsData.trading_desk_link}
                    reviewsTradingDesk={reviewsData.reviews_trading_desk}
                    seeMoreBtn={seeMoreTradingDesk}
                  />
                </TabPanel>
                <TabPanel>
                  <ReviewsTrustPilot
                    trustPilotLink={reviewsData.trustpilot_link}
                    reviewsTrustpilot={reviewsData.reviews_trustpilot}
                    seeMoreBtn={seeMoreTrustPilot}
                  />
                </TabPanel>
                <TabPanel>
                  <ReviewsCryptoGeek
                    cryptoGeekLink={reviewsData.cryptogeek_link}
                    reviewsCryptoGeek={reviewsData.reviews_cryptogeek}
                    seeMoreBtn={seeMoreCryprogeek}
                  />
                </TabPanel>
              </Tabs>
            </LazyHydrate>
          </Row>
        </Container>
      </div>
    </Section>
  )
}

const spring = {
  type: "spring",
  stiffness: 500,
  damping: 30,
}

export default Reviews
