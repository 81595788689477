import React from "react"
import CryptoGeekReview from "../../../ReviewsPage/ReviewsCryptoGeek/CryptoGeekReview/CryptoGeekReview"

// Styles
import * as styles from "./ReviewsCryptoGeek.module.sass"
import Button from "../../../../ui-kit/Button/Button"

const ReviewsCryptoGeek = ({
  cryptoGeekLink,
  reviewsCryptoGeek,
  seeMoreBtn,
}) => {
  return (
    <div className={styles.content}>
      <div className={styles.reviewsBlock}>
        {[reviewsCryptoGeek[0], reviewsCryptoGeek[1]]
          .filter(Boolean)
          .map(review => (
            <CryptoGeekReview
              key={review.id}
              rating={review.rating}
              time={review.time}
              reviewerName={review.reviewer_name}
              text={review.text}
            />
          ))}
      </div>

      <Button
        className={styles.seeMoreBtn}
        external
        link={cryptoGeekLink}
        theme="small"
      >
        {seeMoreBtn}
      </Button>
    </div>
  )
}

export default ReviewsCryptoGeek
