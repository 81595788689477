// extracted by mini-css-extract-plugin
export var animation = "VerifiedDataCentersPlaceholder-module--animation--AHx-d";
export var avatar = "VerifiedDataCentersPlaceholder-module--avatar--gMKJ5";
export var block = "VerifiedDataCentersPlaceholder-module--block--Id2XS";
export var bottom = "VerifiedDataCentersPlaceholder-module--bottom--6uDtQ";
export var card = "VerifiedDataCentersPlaceholder-module--card--d9Tor";
export var coming = "VerifiedDataCentersPlaceholder-module--coming--3CzIm";
export var line = "VerifiedDataCentersPlaceholder-module--line--Hq95G";
export var load = "VerifiedDataCentersPlaceholder-module--load--K8tLj";
export var middle = "VerifiedDataCentersPlaceholder-module--middle--+j-DU";
export var nameStar = "VerifiedDataCentersPlaceholder-module--nameStar--xD2lW";
export var top = "VerifiedDataCentersPlaceholder-module--top--qd+KJ";