import cx from "classnames"
import React, { useEffect, useRef, useState } from "react"

import * as s from "./TradingDeskRating.module.sass"

const OFFSET_X = 20

const TradingDeskRating = ({
  rating = 0,
  size = "default",
  ratingMode = false,
  onChange,
  disabled,
  ...props
}) => {
  const [ratingVal, setRating] = useState(rating)
  const multiplicator = size === "default" ? OFFSET_X : 30
  const getPercentageRating = val => multiplicator * val
  const starWrapper = useRef(null)
  const handleClickRating = e => {
    if (ratingMode && onChange && starWrapper.current !== null) {
      const rect = starWrapper.current.getBoundingClientRect()
      const x = e.clientX - rect.left + OFFSET_X
      const r = Math.floor((x / rect.width) * 5)
      setRating(r > 0 ? r : 1)
      e.persist()
      onChange(r > 0 ? r : 1)
    }
  }

  useEffect(() => {
    setRating(rating)
  }, [rating])
  return (
    <>
      <div className={s.ratingWrapper}>
        <div
          className={cx({
            [s.starWrapper]: size === "default",
            [s.starWrapperBig]: size === "large",
          })}
          onClick={handleClickRating}
        >
          <div
            ref={starWrapper}
            className={cx({
              [s.starSprite]: size === "default",
              [s.starSpriteBig]: size === "large",
            })}
          >
            <span
              style={{ width: `${getPercentageRating(ratingVal)}px` }}
              className={cx({
                [s.starRating]: size === "default",
                [s.starRatingBig]: size === "large",
              })}
            />
          </div>
        </div>
        <input
          value={ratingVal}
          disabled
          className={s.ratingNumber}
          {...props}
        />
      </div>
    </>
  )
}

export default TradingDeskRating
