import React from "react"
import dayjs from "dayjs"
import CryptoGeekRating from "../../../../ui-kit/CryptoGeekRating/CryptoGeekRating"
import * as styles from "./CryptoGeekReview.module.sass"

const CryptoGeekReview = ({ rating, time, reviewerName, text }) => {
  return (
    <div className={styles.reviewContainer}>
      <div className={styles.reviewData}>
        <span className={styles.reviewerName}>{`${reviewerName}, `}</span>
        <span className={styles.reviewTime}>
          {dayjs(time).format("DD MMM")}
        </span>
      </div>
      <div className={styles.reviewRow}>
        <CryptoGeekRating rating={rating} />
      </div>

      <p className={styles.reviewText}>{text}</p>
    </div>
  )
}

export default CryptoGeekReview
